define('day-one/_pods/school-events/controller', ['exports', 'ember-cli-pagination/computed/paged-array', 'moment'], function (exports, _pagedArray, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var multifileStorage = function (ref, files, id) {
        if (files) return Promise.all(files.map(function (file) {
            var imageRef = ref.child(`images/${id}/${file.name}`);
            return imageRef.put(file);
        }));
    };
    exports.default = Ember.Controller.extend({
        firebaseApp: Ember.inject.service(),
        showConfirmationDialog: false,
        removedEventObj: null,
        uploader: Ember.inject.service('file-queue'),

        paginationTitle: Ember.computed('page', 'totalPages', function () {
            return `<small>Page ${this.page} of  ${this.totalPages}</small>`;
        }),

        actions: {
            addEvent: function () {
                this.set('showDialog', true);
            },
            closeDialogBox: function () {
                this.set('showDialog', false);
            },
            saveEventAction: function (event, files) {
                var newEvent = this.store.createRecord('event', event);
                newEvent.save().then(data => {
                    const storage = this.firebaseApp.storage();
                    var ref = storage.ref();
                    var fileUploadResponse = multifileStorage(ref, files, data.id);
                    fileUploadResponse.then(filesResponse => {
                        var saveObjects = Promise.all(filesResponse.map(snapshot => {
                            var image = this.store.createRecord('image', {
                                image_url: snapshot.downloadURL,
                                is_default: false
                            });
                            newEvent.get('images').addObject(image);
                            return image.save();
                        }));
                        saveObjects.then(() => {
                            newEvent.save();
                            this.set('showDialog', false);
                        });
                    });
                    this.set("name", "");
                    this.set('location', "");
                    this.set('start', "");
                    this.set('end', "");
                    this.set('room', "");
                    this.set('price', "");
                    this.set('description', "");
                    var uploader = this.uploader;
                    uploader.files.clear();
                    uploader.queues.clear();
                });
            },
            editEventAction: function (data) {
                var post = this.model; // "this" is the controller here
                this.set("name", data.name);
                this.set('location', data.location);
                this.set('room', data.room), this.set('price', data.price), this.set('start', (0, _moment.default)(data.start).format('MM/DD/YYYY'));
                this.set('end', (0, _moment.default)(data.end).format('MM/DD/YYYY'));
                this.set('description', data.description);
                this.set('showDialog', true);
            },
            confirmRemoveEventAction: function (e, data) {
                this.set("showConfirmationDialog", true);
                this.set("removedEventObj", data);
            },
            removeEventAction: function () {
                this.removedEventObj.set('is_deleted', true);
                this.removedEventObj.save();
                this.set("removedEventObj", null);
                this.set("showConfirmationDialog", false);
            },
            closeConfirmationDialog: function () {
                this.set("showConfirmationDialog", false);
                this.set("removedEventObj", null);
            }
        },

        // setup our query params
        queryParams: ["page", "perPage"],

        // set default values, can cause problems if left out
        // if value matches default, it won't display in the URL
        page: 1,
        perPage: 9,

        // can be called anything, I've called it pagedContent
        // remember to iterate over pagedContent in your template
        pagedEvents: (0, _pagedArray.default)('model', {
            page: Ember.computed.alias("parent.page"),
            perPage: Ember.computed.alias("parent.perPage")
        }),

        // binding the property on the paged array
        // to a property on the controller
        totalPages: Ember.computed.oneWay("pagedEvents.totalPages")
    });
});