define("day-one/_pods/school-events/route", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        sessionManager: Ember.inject.service(),
        titleToken() {
            return `Events`;
        },

        universityId: Ember.computed.alias('sessionManager.universityId'),

        model() {
            const universityId = this.universityId;
            return this.store.query("event", {
                orderBy: 'university',
                equalTo: universityId
            });
        }
    });
});