define('day-one/_pods/school-survey/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        // choices: ['User Choice', 'Free Text', 'Multiple Choice', 'Check Box'],
        choices: ['Single Select', 'Multi Select'],
        newRow: false,
        newQuestion: null,
        actions: {
            addNewQuestion() {
                this.set('newRow', true);
                this.set('newQuestionData', {
                    question: undefined,
                    question_type: 1,
                    options: [{}]
                });
            },
            saveQuestion(question) {
                var saveQuestionData = this.store.createRecord('questionnaire', question);
                saveQuestionData.save().then(() => {
                    this.set('newRow', false);
                    this.set('newQuestionData', null);
                });
            },
            updateQuestion(question) {
                question.save();
            },
            deleteQuestion(question) {
                question.destroyRecord();
            },
            removeRow() {
                this.set('newRow', false);
                this.set('newQuestionData', null);
            }

        }
    });
});