define('day-one/components/ember-timepicker', ['exports', 'ember-timepicker/components/ember-timepicker'], function (exports, _emberTimepicker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberTimepicker.default;
    }
  });
});