define('day-one/components/paper-step', ['exports', 'ember-paper-stepper/components/paper-step'], function (exports, _paperStep) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _paperStep.default;
    }
  });
});