define('day-one/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.transition(this.hasClass('tinder-love'), this.use('toRight', { duration: 500, easing: 'easeInOut' }), this.reverse('toLeft', { duration: 500, easing: 'easeInOut' }));

    this.transition(this.hasClass('tinder-hate'), this.use('toLeft', { duration: 500, easing: 'easeInOut' }), this.reverse('toRight', { duration: 500, easing: 'easeInOut' }));

    this.transition(this.hasClass('tinder-back'), this.use('fade', { duration: 500, easing: 'easeInOut' }), this.reverse('fade', { duration: 500, easing: 'easeInOut' }));

    this.transition(this.hasClass('tinder-favorite'), this.use('toUp', { duration: 500, easing: 'easeInOut' }), this.reverse('toDown', { duration: 500, easing: 'easeInOut' }));

    this.transition(this.hasClass('strongly-disagree-answer'), this.use('toDown', { duration: 500, easing: 'easeInOut' }), this.reverse('toUp', { duration: 500, easing: 'easeInOut' }));

    this.transition(this.hasClass('disagree-answer'), this.use('toLeft', { duration: 500, easing: 'easeInOut' }), this.reverse('toRight', { duration: 500, easing: 'easeInOut' }));

    this.transition(this.hasClass('strongly-agree-answer'), this.use('toUp', { duration: 500, easing: 'easeInOut' }), this.reverse('toDown', { duration: 500, easing: 'easeInOut' }));

    this.transition(this.hasClass('agree-answer'), this.use('toRight', { duration: 500, easing: 'easeInOut' }), this.reverse('toLeft', { duration: 500, easing: 'easeInOut' }));

    this.transition(this.hasClass('neutral-answer'), this.use('fade', { duration: 500, easing: 'easeInOut' }), this.reverse('fade', { duration: 500, easing: 'easeInOut' }));

    this.transition(this.hasClass('slide-up-left'), this.toValue(true), this.use('toUp', { duration: 500, easing: 'easeInOut' }), this.reverse('toDown', { duration: 500, easing: 'easeInOut' }));

    this.transition(this.hasClass('slide-up-right'), this.toValue(true), this.use('toUp', { duration: 500, easing: 'easeInOut' }), this.reverse('toDown', { duration: 500, easing: 'easeInOut' }));

    this.transition(this.hasClass('events__list'), this.toValue(true), this.use('toUp', { duration: 1200, easing: 'easeInOut' }), this.reverse('toDown', { duration: 1200, easing: 'easeInOut' }));
  };
});