define('day-one/_pods/components/student/home-card', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        router: Ember.inject.service('-routing'),
        isFavoriteSelected: false,
        isCommented: false,
        actions: {
            onDetailsClick: function (event) {
                this.onDetailsClickAction(event);
            },
            onToggleFavorite: function () {
                var isFavoriteSelected = !this.isFavoriteSelected;
                this.set('isFavoriteSelected', isFavoriteSelected);
                this.followUnfollowAction(this.event, "events", isFavoriteSelected);
            },
            onToggleComment: function () {
                var isCommented = !this.isCommented;
                this.set('isCommented', isCommented);
                // this.AddCommentAction(this.get('event'), "events", isCommented);
            },
            addComment: function () {
                var isCommented = !this.isCommented;
                this.set('isCommented', isCommented);
                var comment = this.comment;
                this.AddCommentAction(this.event, "events", comment);
            }

        }
    });
});