define('day-one/models/course', ['exports', 'day-one/models/base-type', 'ember-data/relationships', 'ember-data/attr'], function (exports, _baseType, _relationships, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseType.default.extend({
    university: (0, _relationships.belongsTo)('university'),
    college: (0, _relationships.belongsTo)('college'),
    subject: (0, _relationships.belongsTo)('subject'),
    number: (0, _attr.default)('number')
  });
});