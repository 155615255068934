define('day-one/models/student-group', ['exports', 'day-one/models/base-type', 'ember-data/relationships'], function (exports, _baseType, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseType.default.extend({
    group: (0, _relationships.belongsTo)('group'),
    student: (0, _relationships.belongsTo)('student')

  });
});