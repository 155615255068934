define('day-one/models/student-course', ['exports', 'day-one/models/base-type', 'ember-data/relationships'], function (exports, _baseType, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseType.default.extend({
    student: (0, _relationships.belongsTo)('student'),
    course: (0, _relationships.belongsTo)('course')
  });
});