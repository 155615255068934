define('day-one/models/student-country', ['exports', 'ember-data/attr', 'ember-data/relationships', 'day-one/models/base-model'], function (exports, _attr, _relationships, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModel.default.extend({
    student: (0, _relationships.belongsTo)('student', { async: false, inverse: 'countries' }),
    country: (0, _relationships.belongsTo)('country', { async: false }),
    bornThere: (0, _attr.default)('boolean'),
    parentFrom: (0, _attr.default)('boolean'),
    livedIn: (0, _attr.default)('boolean'),
    visited: (0, _attr.default)('boolean'),
    likeToVisit: (0, _attr.default)('boolean')

  });
});