define('day-one/models/answer', ['exports', 'ember-data', 'day-one/models/base-type'], function (exports, _emberData, _baseType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseType.default.extend({
    question: _emberData.default.belongsTo('question'),
    score: _emberData.default.attr('number'),
    type: _emberData.default.belongsTo('answer-type')
  });
});