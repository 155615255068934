define('day-one/_pods/student-profile/index/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        activate: function () {
            Ember.$('body').addClass("bg-color-white");
        },
        setupController(controller, model) {
            let sessionInfo = this.get('session.currentUser');
            if (sessionInfo && sessionInfo.providerData && sessionInfo.providerData[0].providerId === 'twitter.com') {
                controller.set("first_name", sessionInfo.displayName);
                controller.set("image_url", sessionInfo.photoURL);
                if (sessionInfo.emailVerified) controller.set("email", sessionInfo.email);
                if (sessionInfo.identifierNumber) controller.set("phone", sessionInfo.identifierNumber);
                controller.set("hidePassword", true);
            }
        }
    });
});