define("day-one/_pods/student/index/controller", ["exports", "ember-autosave", "ember-concurrency"], function (exports, _emberAutosave, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    sessionManager: Ember.inject.service(),
    studentModel: Ember.computed.alias('sessionManager.student'),
    student: (0, _emberAutosave.default)('studentModel'),

    musicGenres: Ember.computed.alias('sessionManager.musicGenres'),
    sports: Ember.computed.alias('sessionManager.sports'),
    groupTypes: Ember.computed.alias('sessionManager.groupTypes'),
    university: Ember.computed.alias('student.university'),
    colleges: Ember.computed.alias('sessionManager.colleges'),
    interests: Ember.computed.alias('sessionManager.interests'),
    universities: Ember.computed.alias('sessionManager.universities'),

    generalInterests: Ember.computed('interests', function () {
      if (this.get('interests.length')) {
        return this.interests.filter(obj => {
          return obj.get('parentInterest') === 'general';
        });
      }
    }),

    enableApp: (0, _emberConcurrency.task)(function* () {
      const student = yield this.get('studentModel');
      student.set('enabled', true);
      student.save();
      this.transitionToRoute('student-profile.index');
      return Ember.RSVP.Promise.resolve();
    }),

    confirmUniversity: (0, _emberConcurrency.task)(function* () {
      const sessionManager = yield this.get('sessionManager');
      const hasUniversity = yield sessionManager.get('universityId');
      const url = yield this.get('emailDomain');
      const universities = yield this.get('universities');

      if (url && !hasUniversity) {
        const unis = yield this.store.query('university', { orderBy: 'url', equalTo: url });
        const uni = yield unis.get('firstObject');
        const universityId = uni.get('id');
        this.set('student.university', uni);
        const universityData = yield this.get('loadStudentUniversityData').perform(universityId);
        yield sessionManager.setProperties(universityData);
      }

      return Ember.RSVP.Promise.resolve();
    }),

    confirmColleges: (0, _emberConcurrency.task)(function* () {
      const addStudentCollegeTask = yield this.get('addStudentCollege');
      const student = yield this.get('studentModel');
      const selectedCollegeChoices = yield this.get('selectedCollegeChoices');
      const toAdd = [];

      selectedCollegeChoices.forEach(function (scc) {
        toAdd.push(addStudentCollegeTask.perform(scc.dataModel, student));
      });

      yield (0, _emberConcurrency.all)(toAdd);

      return Ember.RSVP.Promise.resolve();
    }),

    addStudentCollege: (0, _emberConcurrency.task)(function* (college, student) {
      const studentCollege = this.store.createRecord('student-college', {
        college,
        student
      });

      yield studentCollege.save();

      return Ember.RSVP.Promise.resolve();
    }),

    confirmSubjects: (0, _emberConcurrency.task)(function* () {
      const selectedSubjectChoices = yield this.get('selectedSubjectChoices');
      const addStudentSubjectTask = yield this.get('addStudentSubject');
      const student = yield this.get('studentModel');
      const toAdd = [];

      selectedSubjectChoices.forEach(function (scc) {
        toAdd.push(addStudentSubjectTask.perform(scc.dataModel, student));
      });

      yield (0, _emberConcurrency.all)(toAdd);

      return Ember.RSVP.Promise.resolve();
    }),

    addStudentSubject: (0, _emberConcurrency.task)(function* (subject, student) {
      const studentSubject = this.store.createRecord('student-subject', {
        subject,
        student
      });

      yield studentSubject.save();

      return Ember.RSVP.Promise.resolve();
    }),

    confirmInterests: (0, _emberConcurrency.task)(function* () {
      const selectedInterestChoices = yield this.get('selectedInterestChoices');
      const addStudentInterestTask = yield this.get('addStudentInterest');
      const student = yield this.get('studentModel');
      const toAdd = [];
      debugger;

      selectedInterestChoices.forEach(function (scc) {
        toAdd.push(addStudentInterestTask.perform(scc.dataModel, student));
      });

      yield (0, _emberConcurrency.all)(toAdd);

      return Ember.RSVP.Promise.resolve();
    }),

    addStudentInterest: (0, _emberConcurrency.task)(function* (interest, student) {
      debugger;
      const studentInterest = this.store.createRecord('student-interest', {
        interest,
        student
      });

      yield studentInterest.save();

      return Ember.RSVP.Promise.resolve();
    }),

    emailValidation: [{
      message: 'Please provide email in a valid format',
      validate: inputValue => {
        let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(inputValue);
      }
    }],

    emailDomain: Ember.computed('student.schoolEmail', function () {
      var email = this.get('student.schoolEmail');
      return email && email.length ? email.replace(/.*@/, "") : email;
    }),

    subjectSummary: Ember.computed('selectedSubjectChoices.@each', function () {
      let summary = '';
      if (this.get('selectedSubjectChoices.length')) {
        this.selectedSubjectChoices.forEach(choice => {
          summary += choice.name + " ";
        });
      }
      return summary;
    }),

    selectedSubjectChoices: Ember.computed('subjectChoices.@each.enabled', function () {
      if (this.get('subjectChoices.length')) {
        return this.subjectChoices.filter(obj => {
          return obj.enabled;
        });
      }
    }),

    subjectChoices: Ember.computed('selectedCollegeChoices', function () {
      const subjectChoices = [];
      const self = this;
      if (this.get('selectedCollegeChoices.length')) {
        this.selectedCollegeChoices.forEach(function (collegeObj) {
          collegeObj.dataModel.get('subjects').forEach(function (subject) {
            subjectChoices.push({
              dataModel: subject,
              name: subject.get('name'),
              enabled: false
            });
          });
        });
      }
      return subjectChoices;
    }),

    collegeSummary: Ember.computed('selectedCollegeChoices.@each', function () {
      let summary = '';
      if (this.get('selectedCollegeChoices.length')) {
        this.selectedCollegeChoices.forEach(choice => {
          summary += choice.name + " ";
        });
      }
      return summary;
    }),

    selectedCollegeChoices: Ember.computed('collegeChoices.@each.enabled', function () {
      if (this.get('collegeChoices.length')) {
        return this.collegeChoices.filter(obj => {
          return obj.enabled;
        });
      }
    }),

    collegeChoices: Ember.computed('colleges', function () {
      if (this.get('colleges.length')) {
        return this.colleges.map(obj => {
          return {
            dataModel: obj,
            name: obj.get('name'),
            enabled: false
          };
        });
      }
    }),

    interestSummary: Ember.computed('selectedInterestChoices.@each', function () {
      let summary = '';
      if (this.get('selectedInterestChoices.length')) {
        this.selectedInterestChoices.forEach(choice => {
          summary += choice.name + " ";
        });
      }
      return summary;
    }),

    selectedInterestChoices: Ember.computed('interestChoices.@each.enabled', function () {
      if (this.get('interestChoices.length')) {
        return this.interestChoices.filter(obj => {
          return obj.enabled;
        });
      }
    }),

    interestChoices: Ember.computed('generalInterests', function () {
      if (this.get('generalInterests.length')) {
        return this.generalInterests.map(obj => {
          return {
            dataModel: obj,
            name: obj.get('name'),
            enabled: false
          };
        });
      }
    }),

    actions: {
      emailValidation: function (e) {
        const email = e.target.value;
        var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailPattern.test(email)) {
          this.set('errorMessage', "Invalid Email");
        } else {
          this.set('errorMessage', "");
        }
      },
      passwordSignIn: function () {
        this.session.open('firebase', {
          provider: 'password',
          email: this.email,
          password: this.password
        }).then(() => {
          this.set('errorMessage', '');
          this.get('sessionManager.setupAdminSession').perform();
          this.transitionToRoute("school.index");
        }).catch(err => {
          this.set('errorMessage', "Invalid username or password");
          console.log(err);
        });
      }
    }
  });
});