define('day-one/models/country-language', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    country: _emberData.default.belongsTo('country'),
    language: _emberData.default.belongsTo('language'),
    percentFirst: _emberData.default.attr('number'),
    percentSecond: _emberData.default.attr('number'),
    percentThird: _emberData.default.attr('number'),
    official: _emberData.default.attr('boolean')
  });
});