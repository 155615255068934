define('day-one/_pods/components/school/subject-card/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init() {
            this._super(...arguments);
        },
        actions: {
            editCollege: function (college) {
                this.editCollegeAction(college);
            },
            confirmRemoveCollege: function (college) {
                this.confirmRemoveCollegeAction(college);
            }
        }
    });
});