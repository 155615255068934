define("day-one/_pods/school-courses/index/route", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        sessionManager: Ember.inject.service(),
        titleToken() {
            return `Courses`;
        },

        model() {
            return this.modelFor("school-courses");
        }
    });
});