define('day-one/models/personality', ['exports', 'ember-data/attr', 'ember-data/relationships', 'day-one/models/base-model', 'ember-concurrency'], function (exports, _attr, _relationships, _baseModel, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModel.default.extend({
    student: (0, _relationships.belongsTo)('student', { async: true, inverse: null }),
    test: (0, _relationships.belongsTo)('personality-test', { async: true, inverse: null }),
    answers: (0, _relationships.hasMany)('personality-answer', { async: true, inverse: null }),
    aspects: (0, _relationships.hasMany)('personality-aspect', { async: true, inverse: null }),
    personalityType: (0, _relationships.belongsTo)('personality-type', { async: true, inverse: null }),
    locked: (0, _attr.default)('boolean', { defaultValue: false }),
    oScore: (0, _attr.default)('number'),
    cScore: (0, _attr.default)('number'),
    eScore: (0, _attr.default)('number'),
    aScore: (0, _attr.default)('number'),
    nScore: (0, _attr.default)('number'),
    selectedSequence: (0, _attr.default)('number', { defaultValue: 1 }),

    aspectSort: ['aspectType.sequence'],
    answersSequenceSort: ['question.sequence'],

    // Types and Generic Data
    sortedAspects: Ember.computed.sort('aspects', 'aspectSort'),
    sortedAnswers: Ember.computed.sort('answers', 'answersSequenceSort'),

    complete: Ember.computed.not('incomplete'),
    incomplete: Ember.computed.alias('unanswered.length'),

    ready: Ember.computed('answers.length', 'test.questions.length', 'complete', 'aspects.length', 'facets.length', function () {
      const answersReady = this.get('answers.length') >= 10 && this.get('answers.lastObject');
      const questionsReady = this.get('test.questions.length') >= 10 && this.get('test.questions.lastObject');
      const questionsMatchAnswers = this.get('test.questions.length') === this.get('answers.length');
      const hadAspectsAndfacets = this.get('aspects.length') && this.get('facets.length');
      return hadAspectsAndfacets && answersReady && questionsReady && questionsMatchAnswers && !this.complete;
    }),

    unanswered: Ember.computed('sortedAnswers.@each.updated', function () {
      return this.sortedAnswers.filter(function (answer) {
        return !answer.get('updated');
      });
    }),

    answered: Ember.computed('sortedAnswers.@each.updated', function () {
      return this.sortedAnswers.filter(function (answer) {
        return answer.get('updated');
      });
    }),

    percentComplete: Ember.computed('answered.length', 'unanswered.length', function () {
      const unanswered = this.get('unanswered.length');
      const answered = this.get('answered.length');
      const total = answered + unanswered;
      const complete = answered / total * 100;
      return complete.toFixed(0);
    }),

    currentAspectTypeId: Ember.computed('currentAnswer.question.facetType.aspectType.id', function () {
      return this.get('currentAnswer.question.facetType.aspectType.id');
    }),

    currentAspect: Ember.computed('aspects.@each', 'currentAspectTypeId', function () {
      const aspectTypeId = this.currentAspectTypeId;
      const aspects = this.aspects.filter(function (aspect) {
        return aspect.get('aspectType', aspectTypeId);
      });
      return aspects && aspects.get('length') ? aspects.get('firstObject') : null;
    }),

    currentFacetTypeId: Ember.computed('currentAnswer.question.facetType.id', function () {
      return this.get('currentAnswer.question.facetType.id');
    }),

    currentFacet: Ember.computed('facets.@each.facetType', 'currentFacetTypeId', function () {
      const facetTypeId = this.currentFacetTypeId;
      const facets = this.facets.filter(function (facet) {
        console.log(facet.get('facetType'));
        console.log(facet.get('facetType.id'), "===", facetTypeId);
        return facet.get('facetType.id') === facetTypeId;
      });
      return facets && facets.get('length') === 1 ? facets.get('firstObject') : null;
    }),

    facets: Ember.computed('aspects.@each.facets', function () {
      let facets = Ember.A();
      this.aspects.forEach(function (aspect) {
        const aspectFacets = aspect.get('facets');
        facets.pushObjects(aspectFacets.toArray());
      });
      return facets;
    }),

    typeKey: Ember.computed('sortedAspects.@each.typeKey', function () {
      let typeKey = "";
      this.sortedAspects.forEach(function (aspect) {
        typeKey = typeKey + aspect.get('typeKey');
      });
      return typeKey;
    }),

    mbtiEquivalent: Ember.computed('openness.high', 'conscientiousness.high', 'extraversion.high', 'agreeableness.high', 'neuroticism.high', function () {
      const EI = this.get('extraversion.high') ? "E" : "I";
      const NS = this.get('openness.high') ? "N" : "S";
      const TF = this.get('openness.high') && this.get('conscientiousness.high') || this.get('agreeableness.high') ? "F" : "T";
      const JP = this.get('openness.high') && this.get('agreeableness.high') || this.get('conscientiousness.high') ? "P" : "J";
      const AT = this.get('neuroticism.high') ? "T" : "A";
      return EI + NS + TF + JP + "-" + AT;
    }),

    currentAnswer: Ember.computed('unanswered.length', function () {
      return this.get('unanswered.length') ? this.get('unanswered.firstObject') : null;
    }),

    currentAnswerNumber: Ember.computed('currentAnswer', 'answered.length', function () {
      return this.currentAnswer && this.get('answered.length') ? this.get('answered.length') + 1 : 1;
    }),

    scoreTest: function () {
      const self = this;
      this.set('locked', true);
      this.answers.forEach(function (answer) {
        const aspect = self.get('aspects').findBy('id', answer.get('question.facet.aspect.id'));
        aspect.saveAnswerToFacet(answer);
      });
      this.set('locked', false);
    },

    getAspectByType: function (typeId) {
      const self = this;
      const aspects = this.aspects.filter(function (aspect) {
        return aspect.get('aspectType.id') === typeId;
      });
      return aspects.get('firstObject');
    },

    openness: Ember.computed('aspects.@each.aspectType', function () {
      return this.getAspectByType('openness');
    }),
    conscientiousness: Ember.computed('aspects.@each.aspectType', function () {
      return this.getAspectByType('conscientiousness');
    }),
    extraversion: Ember.computed('aspects.@each.aspectType', function () {
      return this.getAspectByType('extraversion');
    }),
    agreeableness: Ember.computed('aspects.@each.aspectType', function () {
      return this.getAspectByType('agreeableness');
    }),
    neuroticism: Ember.computed('aspects.@each.aspectType', function () {
      return this.getAspectByType('neuroticism');
    }),

    setupPersonality: (0, _emberConcurrency.task)(function* () {
      const setupAnswerTask = this.setupAnswerTask;
      const setupAspectTask = this.setupAspectTask;
      const questions = this.get('test.questions');
      const aspectTypes = this.get('test.aspectTypes');
      const questionsForSetup = [];
      const aspectTypesForSetup = [];

      questions.forEach(function (question) {
        questionsForSetup.push(setupAnswerTask.perform(question));
      });
      aspectTypes.forEach(function (type) {
        aspectTypesForSetup.push(setupAspectTask.perform(type));
      });

      yield (0, _emberConcurrency.all)(aspectTypesForSetup);

      yield (0, _emberConcurrency.all)(questionsForSetup);

      yield this.save();
    }),

    setupAnswerTask: (0, _emberConcurrency.task)(function* (question) {
      const student = this.student;
      const personality = this;
      const pa = yield this.store.createRecord('personality-answer', {
        question,
        student,
        personality
      });

      yield pa.save();

      yield this.get('answers').pushObject(pa);
    }),

    setupAspectTask: (0, _emberConcurrency.task)(function* (aspectType) {
      const student = this.student;
      const personality = this;
      const pa = yield this.store.createRecord('personality-aspect', {
        student,
        aspectType,
        personality
      });

      yield this.get('aspects').pushObject(pa);

      yield pa.get('setupFacets').perform();

      // yield pa.save();
    })

  });
});