define('day-one/_pods/school/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model() {

      var data = {
        pieChart: [['Task', 'Hours per Day'], ['Likes', 11], ['Comments', 9], ['Registered', 10]],
        areaChart: [['Month', 'Music', 'Game', 'Drawing'], ['jan', 1000, 400, 700], ['feb', 1170, 460, 360], ['mar', 660, 1120, 654], ['apr', 1030, 540, 714], ['may', 1030, 360, 150], ['jun', 520, 270, 864], ['jul', 501, 200, 340], ['aug', 100, 555, 444], ['sep', 250, 630, 458]]

      };
      return data;
    }

  });
});