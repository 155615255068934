define('day-one/models/college', ['exports', 'day-one/models/base-type', 'ember-data/attr', 'ember-data/relationships'], function (exports, _baseType, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseType.default.extend({
    university: (0, _relationships.belongsTo)('university'),

    subjects: Ember.computed('id', function () {
      const id = this.id;
      return this.store.query('subject', {
        equalTo: id,
        orderBy: 'college'
      });
    }),

    courses: Ember.computed('id', function () {
      const id = this.id;
      return this.store.query('course', {
        equalTo: id,
        orderBy: 'college'
      });
    })
  });
});