define('day-one/_pods/school-groups/index/controller', ['exports', 'ember-cli-pagination/computed/paged-array'], function (exports, _pagedArray) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        showConfirmationDialog: false,
        removedClubObj: null,
        paginationTitle: Ember.computed('page', 'totalPages', function () {
            return `<small>Page ${this.page} of  ${this.totalPages}</small>`;
        }),
        actions: {
            addclub: function () {
                this.set("name", "");
                this.set("url", "");
                this.set('email', "");
                this.set('description', "");
                this.set('showDialog', true);
            },
            closeDialogBox: function () {
                this.set('showDialog', false);
            },
            saveClubAction: function (club) {
                var newClub = this.store.createRecord('activity', club);
                newClub.save();
                this.set('showDialog', false);
            },
            editClubAction: function (data) {
                this.set("name", data.name);
                this.set('email', data.email);
                this.set("url", data.url);
                this.set('description', data.description);
                this.set('showDialog', true);
            },
            confirmRemoveClubAction: function (e, data) {
                this.set("showConfirmationDialog", true);
                this.set("removedClubObj", data);
            },
            removeClubAction: function () {
                this.removedClubObj.set('is_deleted', true);
                this.removedClubObj.save();
                this.set("removedClubObj", null);
                this.set("showConfirmationDialog", false);
            },
            closeConfirmationDialog: function () {
                this.set("showConfirmationDialog", false);
                this.set("removedClubObj", null);
            }
        },

        // setup our query params
        queryParams: ["page", "perPage"],

        // set default values, can cause problems if left out
        // if value matches default, it won't display in the URL
        page: 1,
        perPage: 6,

        // can be called anything, I've called it pagedContent
        // remember to iterate over pagedContent in your template
        pagedGroups: (0, _pagedArray.default)('model', {
            page: Ember.computed.alias("parent.page"),
            perPage: Ember.computed.alias("parent.perPage")
        }),

        // binding the property on the paged array
        // to a property on the controller
        totalPages: Ember.computed.oneWay("pagedGroups.totalPages")
    });
});