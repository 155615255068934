define('day-one/_pods/application/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function () {
      debugger;
      return this.transitionTo('student-portal');
    }
  });
});