define('day-one/_pods/school/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    sessionManager: Ember.inject.service(),
    titleToken() {
      return this.get('sessionManager.university.shortName');
    }
  });
});