define('day-one/_pods/components/student/agenda-sidebar/component', ['exports', 'moment', 'ember-concurrency', 'ember-power-calendar-utils'], function (exports, _moment, _emberConcurrency, _emberPowerCalendarUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sessionManager: Ember.inject.service(),
    store: Ember.inject.service(),
    studentEvents: Ember.computed.alias('sessionManager.studentEvents'),
    universityId: Ember.computed.alias('sessionManager.universityId'),
    classNames: ['events-container'],

    events: Ember.computed('universityId', function () {
      const universityId = this.universityId;
      return this.store.query("event", {
        orderBy: 'university',
        equalTo: universityId
      });
    }),

    dateSort: ['start'],
    eventDateSort: ['event.start'],

    sortedEvents: Ember.computed.sort('events', 'dateSort'),
    sortedStudentEvents: Ember.computed.sort('evstudentEventsents', 'eventDateSort'),

    nonSeriesEvents: Ember.computed('sortedEvents.length', function () {
      const events = this.get('sortedEvents');
      if (events && events.get('length')) {
        return events.filter(function (event) {
          return !event.get('isSeries');
        });
      }
    }),

    filteredEvents: Ember.computed('nonSeriesEvents.length', function () {
      const events = this.get('nonSeriesEvents');
      if (events && events.get('length')) {
        return events.slice(0, 20);
      }
    })

    // calendarDay: computed('day', function() {
    //   const day = this.get('day');
    //   const mDay = day ? moment(day) : null;
    //   return mDay;
    // }),

    // days: computed('day', 'month', function() {
    //   const selectedDay = moment(this.day);
    //   const month = moment(this.month);
    //   let nowMoment = moment();
    //   let day = startOf(startOf(month, 'month'), 'week');
    //   let lastDay = endOf(endOf(month, 'month'), 'week');
    //   let days = [];
    //   while (isBefore(day, lastDay)) {
    //       let copy = moment(day);
    //       let isSelectedDay = selectedDay != null && copy.isSame(selectedDay, 'day');
    //       let isCurrentMonth = month.isSame(copy, 'month');
    //       let isCurrentDay = nowMoment.isSame(copy, 'day');
    //       let styles = isCurrentDay ? 'calendar-table__col calendar-table__today' : 'calendar-table__col';
    //       styles = !isCurrentMonth ? `${styles} calendar-table__inactive` : styles;
    //       styles = isSelectedDay ? `${styles} calendar-table__event` : styles;
    //       days.push({
    //         date: copy,
    //         isCurrentMonth,
    //         isCurrentDay,
    //         styles
    //       });
    //     // }
    //     day = add(day, 1, "day");
    //   }
    //   return days;
    // }),

    // weeks: computed('days', function() {
    //   let days = this.get('days');
    //   let weeks = [];
    //   let i = 0;
    //   while (days[i]) {
    //     weeks.push({ days: days.slice(i, i + 7) });
    //     i += 7;
    //   }
    //   return weeks;
    // })
  });
});