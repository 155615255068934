define('day-one/_pods/components/student/home-course-card', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isFavoriteSelected: false,
        actions: {
            onLikeToggle: function () {
                var isFavoriteSelected = !this.isFavoriteSelected;
                this.set('isFavoriteSelected', isFavoriteSelected);
                this.followUnfollowAction(this.course, "courses", isFavoriteSelected);
            }
        }
    });
});