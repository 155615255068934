define('day-one/_pods/school-portal/school-redirect/route', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    sessionManager: Ember.inject.service(),

    beforeModel() {
      const isAuthenticated = this.get('session.isAuthenticated');
      if (isAuthenticated) {
        this.get('sessionManager.setupAdminSession').perform();
        const x = this.get('sessionManager.university');
        this.transitionTo('school.index');
      }
    },
    model(params) {
      if (params.school_id) {
        return this.store.findRecord('university', `university-${params.school_id}`);
      }
    },
    setupController(controller, model) {
      this.set('sessionManager.university', model);
      this.transitionTo('school-portal.index');
    }
  });
});