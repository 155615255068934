define("day-one/_pods/student/controller", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        rightSideBarOpen: false,

        actions: {
            toggle: function (field) {
                this.set(field, !this.get(field));
            },
            signOut: function () {
                this.session.close();
                this.transitionToRoute(`student-portal.index`);
            }
        }
    });
});