define('day-one/components/paper-expansion-panel', ['exports', 'ember-paper-expansion-panel/components/paper-expansion-panel'], function (exports, _paperExpansionPanel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _paperExpansionPanel.default;
    }
  });
});