define('day-one/models/engagement-score', ['exports', 'day-one/models/base-score', 'ember-data/attr', 'ember-data/relationships'], function (exports, _baseScore, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseScore.default.extend({
    previousScore: (0, _relationships.belongsTo)('engagement-score', { async: false }),
    // previousScore.sequence + 1
    sequence: (0, _attr.default)('number'),
    // if(previousScore.currentTotal) { previousScore.currentTotal + this.numericScore } else { previousScore.numericScore + this.numericScore }
    currentTotal: (0, _attr.default)('number'),
    // this.currentTotal / this.sequence
    historicalAverage: (0, _attr.default)('number'),
    // previousScore.numericScore < this.numericScore
    trendingUpwards: (0, _attr.default)('boolean')
  });
});