define('day-one/models/personality-facet-descriptor', ['exports', 'ember-data/attr', 'ember-data/relationships', 'day-one/models/base-model'], function (exports, _attr, _relationships, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModel.default.extend({
    facetType: (0, _relationships.belongsTo)('personality-facet-type', { async: true, inverse: null }),
    name: (0, _attr.default)('string'),
    highFacet: (0, _attr.default)('boolean')

  });
});