define('day-one/_pods/components/school/event-add/component', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        files: null,
        timeOption: {
            dropdown: true,
            zindex: 99,
            timeFormat: "hh:mm p"
        },
        uploader: Ember.inject.service('file-queue'),
        actions: {
            closeDialog: function () {
                var uploader = this.uploader;
                uploader.files.clear();
                uploader.queues.clear();
                this.closeDialogBox();
            },
            saveEvent: function () {
                if (!this.files) {
                    this.set("file-upload-error", "Please upload image file");
                    return;
                }
                var newEvent = {
                    name: this.name,
                    location: this.location,
                    room: this.room,
                    price: this.price,
                    start: new Date(this.start),
                    end: new Date(this.end),
                    start_time: this.start_time,
                    end_time: this.end_time,
                    description: this.description,
                    type: 'event'
                };
                this.saveEventAction(newEvent, this.files);
            },
            upload(file) {
                this.set("file-upload-error", "");
                if (!this.files) {
                    this.files = [];
                }
                this.files.push(file.blob);
                file.readAsDataURL().then(function (url) {
                    file.set('url', url);
                });
            },
            onChange(value, selectedTime) {
                var data = (0, _moment.default)(selectedTime).format('hh:mm a');
                this.set(value, data);
            }
        }

    });
});