define('day-one/_pods/components/mobi-calendar/component', ['exports', 'moment', 'ember-concurrency', 'ember-power-calendar-utils'], function (exports, _moment, _emberConcurrency, _emberPowerCalendarUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sessionManager: Ember.inject.service(),
    allEvents: Ember.computed.alias('sessionManager.events'),

    dateSort: ['start'],

    sortedEvents: Ember.computed.sort('events', 'dateSort'),

    filteredEvents: Ember.computed('sortedEvents.length', 'calendarMonth', 'calendarDay', 'day', 'month', function () {
      const events = this.get('sortedEvents');
      const calendarDay = this.get('calendarDay');
      const calendarMonth = this.get('calendarMonth');

      if (events.get('length')) {
        if (calendarDay && calendarDay.isSame(calendarMonth, 'month')) {
          return events.filter(function (event) {
            const eventDay = event.get('start');
            const eventMoment = (0, _moment.default)(eventDay);
            const isSame = eventMoment.isSame(calendarDay, 'day');
            return isSame;
          });
        } else {
          return events.filter(function (event) {
            const eventDay = event.get('start');
            const eventMoment = (0, _moment.default)(eventDay);
            const isSame = eventMoment.isSame(calendarMonth, 'month');
            return isSame;
          });
        }
      }
    }),

    calendarMonth: Ember.computed('month', function () {
      return (0, _moment.default)(this.get('month'));
    }),

    calendarDay: Ember.computed('day', function () {
      const day = this.get('day');
      const mDay = day ? (0, _moment.default)(day) : null;
      return mDay;
    }),

    days: Ember.computed('day', 'month', function () {
      const selectedDay = (0, _moment.default)(this.day);
      const month = (0, _moment.default)(this.month);
      let nowMoment = (0, _moment.default)();
      let day = (0, _emberPowerCalendarUtils.startOf)((0, _emberPowerCalendarUtils.startOf)(month, 'month'), 'week');
      let lastDay = (0, _emberPowerCalendarUtils.endOf)((0, _emberPowerCalendarUtils.endOf)(month, 'month'), 'week');
      let days = [];
      while ((0, _emberPowerCalendarUtils.isBefore)(day, lastDay)) {
        let copy = (0, _moment.default)(day);
        let isSelectedDay = selectedDay != null && copy.isSame(selectedDay, 'day');
        let isCurrentMonth = month.isSame(copy, 'month');
        let isCurrentDay = nowMoment.isSame(copy, 'day');
        let styles = isCurrentDay ? 'calendar-table__col calendar-table__today' : 'calendar-table__col';
        styles = !isCurrentMonth ? `${styles} calendar-table__inactive` : styles;
        styles = isSelectedDay ? `${styles} calendar-table__event` : styles;
        days.push({
          date: copy,
          isCurrentMonth,
          isCurrentDay,
          styles
        });
        // }
        day = (0, _emberPowerCalendarUtils.add)(day, 1, "day");
      }
      return days;
    }),

    weeks: Ember.computed('days', function () {
      let days = this.get('days');
      let weeks = [];
      let i = 0;
      while (days[i]) {
        weeks.push({ days: days.slice(i, i + 7) });
        i += 7;
      }
      return weeks;
    })
  });
});