define('day-one/models/event', ['exports', 'day-one/models/base-type', 'ember-data/relationships', 'ember-data/attr'], function (exports, _baseType, _relationships, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseType.default.extend({
        university: (0, _relationships.belongsTo)('university'),
        location: (0, _attr.default)('string'),
        subTitle: (0, _attr.default)('string'),
        subLocation: (0, _attr.default)('string'),
        room: (0, _attr.default)('string'),
        url: (0, _attr.default)('string'),
        price: (0, _attr.default)('string'),
        start: (0, _attr.default)('date'),
        end: (0, _attr.default)('date'),
        isSeries: (0, _attr.default)('boolean'),
        type: (0, _relationships.belongsTo)('event-type'),
        nextInSeries: (0, _relationships.belongsTo)('event', { inverse: null }),
        previousInSeries: (0, _relationships.belongsTo)('event', { inverse: null }),
        images: (0, _relationships.hasMany)('image'),
        interests: (0, _relationships.hasMany)('interest')
    });
});