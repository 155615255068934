define('day-one/_pods/components/notification-bell/component', ['exports', 'moment', 'ember-concurrency'], function (exports, _moment, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['bell'],
    count: 0,
    classNameBindings: ['count:notify', 'bell'],

    displayCount: Ember.computed('count', function () {
      return this.get('count') < 10 ? this.get('count') : '9';
    }),

    click() {
      this.sendAction('open');
    }
  });
});