define('day-one/_pods/components/event-list/component', ['exports', 'moment', 'ember-concurrency'], function (exports, _moment, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    open: false,

    classNames: ['events__item'],
    eventsTagStyle: Ember.computed('event.isSeries', function () {
      return this.get('event.isSeries') ? 'events__tag events__tag--highlighted' : 'events__tag';
    }),
    click() {
      //this.toggleProperty('open');
    }
  });
});