define('day-one/_pods/student-portal/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    sessionManager: Ember.inject.service(),

    beforeModel: function () {
      return this.session.fetch().catch(function () {});
    },
    setupController() {
      const isAuthenticated = this.get('session.isAuthenticated');
      if (!isAuthenticated) {
        this.transitionTo('student-portal.index');
      } else {
        this.get('sessionManager.setupStudentSession').perform();
      }
    }
  });
});