define('day-one/transitions/rotate-below', ['exports', 'liquid-fire'], function (exports, _liquidFire) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = rotateBelow;
  function rotateBelow(opts = {}) {
    let direction = 1;
    if (opts.direction === 'cw') {
      direction = -1;
    }
    (0, _liquidFire.stop)(this.oldElement);
    if (this.oldElement) {
      this.oldElement.css('transform-origin', '50% 150%');
    }
    if (this.newElement) {
      this.newElement.css('transform-origin', '50% 150%');
    }
    return _liquidFire.Promise.all([(0, _liquidFire.animate)(this.oldElement, { rotateZ: -30 * direction + 'deg' }, opts), (0, _liquidFire.animate)(this.newElement, { rotateZ: ['0deg', 30 * direction + 'deg'] }, opts)]);
  }

  // return function (event) {
  //   var cards = document.querySelectorAll('.tinder--card:not(.removed)');
  //   var moveOutWidth = document.body.clientWidth * 1.5;

  //   if (!cards.length) return false;

  //   var card = cards[0];

  //   card.classList.add('removed');

  //   if (love) {
  //     card.style.transform = 'translate(' + moveOutWidth + 'px, -100px) rotate(-30deg)';
  //   } else {
  //     card.style.transform = 'translate(-' + moveOutWidth + 'px, -100px) rotate(30deg)';
  //   }

  //   initCards();

  //   event.preventDefault();
  // };
});