define('day-one/models/group', ['exports', 'day-one/models/base-type', 'ember-data/attr', 'ember-data/relationships'], function (exports, _baseType, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseType.default.extend({
    email: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    background: (0, _attr.default)('string'),
    interests: (0, _relationships.hasMany)('interest'),
    university: (0, _relationships.belongsTo)('university')
  });
});