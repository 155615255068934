define('day-one/components/paper-step-actions', ['exports', 'ember-paper-stepper/components/paper-step-actions'], function (exports, _paperStepActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _paperStepActions.default;
    }
  });
});