define('day-one/_pods/events/index/controller', ['exports', 'ember-cli-pagination/computed/paged-array', 'moment', 'ember-concurrency'], function (exports, _pagedArray, _moment, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        sessionManager: Ember.inject.service(),
        student: Ember.computed.alias('sessionManager.student'),
        studentInterests: Ember.computed.alias('sessionManager.studentInterests'),
        tinderClass: '',
        dateSort: ['start'],

        goBack: (0, _emberConcurrency.task)(function* () {
            const cssClass = 'tinder-back';
            this.set('tinderClass', cssClass);
            this.set('tinderText', 'Go Back');
            const currentPage = this.get('page');
            yield (0, _emberConcurrency.timeout)(100);
            if (currentPage > 1) {
                this.set('page', this.get('page') - 1);
            }

            return Ember.RSVP.Promise.resolve();
        }),

        favorite: (0, _emberConcurrency.task)(function* (event) {
            const cssClass = 'tinder-favorite';
            this.set('tinderText', 'Favorite');
            this.set('tinderClass', cssClass);
            this.set('eventIsEmpty', true);

            const currentPage = this.get('page');
            yield (0, _emberConcurrency.timeout)(100);
            if (currentPage > 1) {
                this.set('page', currentPage + 1);
            }
            this.set('eventIsEmpty', false);

            return Ember.RSVP.Promise.resolve();
        }),

        reviewStudentEvent: (0, _emberConcurrency.task)(function* (event, scoreChange) {
            const cssClass = scoreChange > 0 ? 'tinder-love' : 'tinder-hate';
            const tindertext = scoreChange > 0 ? 'Love it!' : 'Hate it!';
            this.set('tinderClass', cssClass);

            this.set('tinderText', tindertext);
            this.set('eventIsEmpty', true);
            yield (0, _emberConcurrency.timeout)(100);
            //const newScore = studentEvent.get('score') + scoreChange;
            // const studentInterestRatings = [];
            // const updateStudentInterestsTask = this.get('updateStudentInterests');

            // if(studentEvent.get('interests') && studentEvent.get('interests.length')) {
            //     studentEvent.get('interests').forEach(function(interest) {
            //         studentInterestRatings.push(updateStudentInterestsTask.perform(interest, scoreChange))
            //     });
            //     yield all(studentInterestRatings);
            // }
            //studentEvent.set('score', newScore);
            //yield studentEvent.save();

            this.set('page', this.get('page') + 1);
            this.set('eventIsEmpty', false);

            return Ember.RSVP.Promise.resolve();
        }),

        updateStudentInterests: (0, _emberConcurrency.task)(function* (interest, score) {
            const studentInterests = this.get('studentInterests');
            let thisStudentInterest = yield studentInterests.findBy('interest', interest);
            if (!thisStudentInterest || thisStudentInterest.get('id')) {
                const student = yield this.get('student');

                thisStudentInterest = this.store.createRecord('studentInterest', {
                    interest,
                    student,
                    score
                });
            } else {
                const newScore = studentEvent.get('score') + score;
                thisStudentInterest.set('score', newScore);
            }

            yield thisStudentInterest.save();

            return Ember.RSVP.Promise.resolve();
        }),

        sortedEvents: Ember.computed.sort('model', 'dateSort'),

        filteredEvents: Ember.computed('sortedEvents.length', function () {
            const events = this.get('sortedEvents');
            if (events.get('length')) {
                return events.filter(function (event) {
                    return !event.get('isSeries') || !event.get('previousInSeries');
                });
            }
        }),

        currentEvent: Ember.computed('pagedEvents.length', 'page', 'eventIsEmpty', function () {
            const pes = this.get('pagedEvents');
            const event = pes.objectAt(0);
            return this.get('eventIsEmpty') ? null : event;
        }),

        // setup our query params
        queryParams: ["page", "perPage"],

        // set default values, can cause problems if left out
        // if value matches default, it won't display in the URL
        page: 1,
        perPage: 1,

        // can be called anything, I've called it pagedContent
        // remember to iterate over pagedContent in your template
        pagedEvents: (0, _pagedArray.default)('filteredEvents', {
            page: Ember.computed.alias("parent.page"),
            perPage: Ember.computed.alias("parent.perPage")
        }),

        // binding the property on the paged array
        // to a property on the controller
        totalPages: Ember.computed.oneWay("pagedEvents.totalPages")
    });
});