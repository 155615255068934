define('day-one/models/club', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        club_name: _emberData.default.attr('string'),
        email: _emberData.default.attr('string'),
        url: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        is_deleted: _emberData.default.attr('boolean', { defaultValue: false }),
        like_count: _emberData.default.attr('number', { defaultValue: 0 }),
        comment_count: _emberData.default.attr('number', { defaultValue: 0 })
    });
});