define('day-one/_pods/components/school/club-card/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init() {
            this._super(...arguments);
        },
        actions: {
            editClub: function (data) {
                this.editClubAction(data);
            },
            confirmRemoveClub: function (data) {
                this.confirmRemoveClubAction(data);
            }
        }
    });
});