define('day-one/_pods/assessments/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    renderTemplate() {
      this.render('assessments');
      this.render('assessments.sidebar', { // the template to render
        into: 'my', // the template to render into
        outlet: 'sidebar', // the name of the outlet in that template
        controller: 'my' // the controller to use for the template
      });
    },
    title: 'Assessments / Home'
  });
});