define('day-one/models/personality-type', ['exports', 'ember-data/attr', 'ember-data/relationships', 'day-one/models/base-model'], function (exports, _attr, _relationships, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModel.default.extend({
    student: (0, _relationships.belongsTo)('student', { async: true, inverse: null }),
    name: (0, _attr.default)('string'),
    openness: (0, _attr.default)('boolean'),
    conscientiousness: (0, _attr.default)('boolean'),
    extraversion: (0, _attr.default)('boolean'),
    agreeableness: (0, _attr.default)('boolean'),
    neuroticism: (0, _attr.default)('boolean'),
    displayCode: Ember.computed('openness', 'conscientiousness', 'extraversion', 'agreeableness', 'neuroticism', function () {
      const Oo = this.openness ? 'O' : 'o';
      const Cc = this.conscientiousness ? 'C' : 'c';
      const Ee = this.extraversion ? 'E' : 'e';
      const Aa = this.agreeableness ? 'A' : 'a';
      const Nn = this.neuroticism ? 'N' : 'n';
      return Oo + Cc + Ee + Aa + Nn;
    })
  });
});