define('day-one/_pods/student-portal/index/controller', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),
        sessionManager: Ember.inject.service(),
        firebaseApp: Ember.inject.service(),
        ajax: Ember.inject.service(),

        signOut: (0, _emberConcurrency.task)(function* () {
            this.session.close();
        }).drop(),

        signIn: (0, _emberConcurrency.task)(function* (provider) {
            const registerTask = this.get('register');
            this.session.open("firebase", { provider: provider }).then(function (data) {
                registerTask.perform(data);
            });
        }).drop(),

        register: (0, _emberConcurrency.task)(function* (data) {
            const self = this;
            const sessionManager = this.sessionManager;
            const students = yield self.get('store').query('student', {
                orderBy: 'uid',
                equalTo: data.uid
            });

            if (!students.get('length')) {
                let newStudent = self.store.createRecord('student', {
                    id: data.uid
                });
                yield newStudent.get('setFromSession').perform(data);
                (0, _emberConcurrency.timeout)(100);

                yield newStudent.save();
            }

            yield sessionManager.get('setupStudentSession').perform();

            (0, _emberConcurrency.timeout)(300);

            this.transitionToRoute('student-profile.index');
        }).drop()

    });
});