define('day-one/_pods/student-profile/event-details/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        event: null,
        init() {
            if (!event.hasOwnProperty('name')) {
                this.transitionToRoute("student.main.home");
            }
        },
        actions: {
            backbtn: function () {
                this.transitionToRoute("student.main.home");
            }
        }
    });
});