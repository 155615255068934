define('day-one/_pods/school/index/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        options: {
            responsive: true,
            maintainAspectRatio: false,

            legend: {
                position: 'right'

                //, //	The fill color of the arcs in the dataset. See Colors
                //borderColor: ["#9a0007", "#9a0007", "#9a0007"]
                //, //	The border color of the arcs in the dataset. See Colors
                // hoverBackgroundColor: [], //	The fill colour of the arcs when hovered.
                // hoverBorderColo: [] //The stroke colour of the arcs when hovered.
            } },
        data: {
            datasets: [{
                data: [27, 56, 75],
                backgroundColor: ["#c5cae9", "#ffcdd2", "#c8e6c9"]
            }],

            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: ['High', 'Average', 'At Risk']
        },
        width: 250,

        actions: {
            onEventClick: function (title) {
                this.transitionToRoute(`admin.main.${title.toLowerCase()}`);
            }
        }
    });
});