define('day-one/router', ['exports', 'day-one/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('school-portal', { resetNamespace: true }, function () {
      // this.route('login'); <<-- use index
      this.route('school-redirect', { path: "/:school_id" });
      // this.route('index', { path: '/' });
      this.route('signup');
      this.route('school', { resetNamespace: true }, function () {
        // this.route('dashboard'); <<-- use index
        this.route('school-profile', { path: 'profile', resetNamespace: true }, function () {
          this.route('details');
          this.route('address');
        });

        this.route('school-events', { resetNamespace: true }, function () {
          this.route('school-event', { path: '/:event_id', resetNamespace: true }, function () {
            // INDEX ONLY FOR NOW
          });
        });

        this.route('school-groups', { resetNamespace: true }, function () {
          this.route('school-group', { path: '/:group_id', resetNamespace: true }, function () {
            // INDEX ONLY FOR NOW
          });
        });

        this.route('school-students', { resetNamespace: true }, function () {
          this.route('school-student', { path: '/:student_id', resetNamespace: true }, function () {
            this.route('interests');
            this.route('surveys');
            this.route('courses');
            this.route('personality');
            this.route('events');
          });
        });

        this.route('school-surveys', { resetNamespace: true }, function () {
          this.route('school-survey', { path: '/:survey_id', resetNamespace: true }, function () {
            // INDEX ONLY FOR NOW
          });
        });

        this.route('school-colleges', { path: 'colleges', resetNamespace: true }, function () {
          this.route('school-college', { path: '/:college_id', resetNamespace: true }, function () {
            this.route('school-subjects', { path: 'subjects', resetNamespace: true }, function () {
              this.route('school-subject', { path: '/:subject_id', resetNamespace: true }, function () {
                this.route('school-courses', { path: 'courses', resetNamespace: true }, function () {
                  this.route('school-course', { path: '/:course_id', resetNamespace: true }, function () {});
                });
              });
            });
          });
        });
        // this.route('profile',{ path: '/:id' });
      });
    });

    this.route('student-portal', { resetNamespace: true }, function () {
      // this.route('login'); <<-- use index
      this.route('signup');
      this.route('student', { resetNamespace: true }, function () {
        this.route('student-profile', { path: 'profile', resetNamespace: true }, function () {
          this.route('interests');
          this.route('languages');
          this.route('countries');
          this.route('music');
          this.route('sports');

          this.route('address');
          this.route('info');
          this.route('event-details');
        });
        this.route('events', { resetNamespace: true }, function () {
          this.route('month');
          this.route('list');
          this.route('event', { path: '/:event_id', resetNamespace: true }, function () {
            // INDEX ONLY FOR NOW
          });
        });

        this.route('groups', { resetNamespace: true }, function () {
          this.route('group', { path: '/:group_id', resetNamespace: true }, function () {
            // INDEX ONLY FOR NOW
          });
        });

        this.route('surveys', { resetNamespace: true }, function () {
          this.route('survey', { path: '/:survey_id', resetNamespace: true }, function () {
            // INDEX ONLY FOR NOW
          });
        });

        this.route('courses', { resetNamespace: true }, function () {
          this.route('course', { path: '/:course_id', resetNamespace: true }, function () {
            // INDEX ONLY FOR NOW
          });
        });

        this.route('personality', { resetNamespace: true }, function () {
          this.route('assess');
          this.route('results');
        });
      });
    });
  });

  exports.default = Router;
});