define('day-one/_pods/student-profile/event-details/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        actions: {
            onEventClick: function (title) {
                this.transitionToRoute(`student.${title.toLowerCase()}`);
            }
        }
    });
});