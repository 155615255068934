define('day-one/models/personality-aspect', ['exports', 'ember-data/attr', 'ember-data/relationships', 'day-one/models/base-model', 'ember-concurrency'], function (exports, _attr, _relationships, _baseModel, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModel.default.extend({
    student: (0, _relationships.belongsTo)('student', { async: true, inverse: null }),
    aspectType: (0, _relationships.belongsTo)('personality-aspect-type', { async: true, inverse: null }),
    personality: (0, _relationships.belongsTo)('personality', { async: true, inverse: null }),
    facets: (0, _relationships.hasMany)('personality-facet', { async: true, inverse: null }),
    currenctFacet: null,
    completedScore: (0, _attr.default)('number'),
    completed: (0, _attr.default)('boolean'),
    incomplete: Ember.computed('facets.@each', 'currenctFacet', function () {
      return this.facets.filter(function (pfs) {
        return pfs.get('nextAnswer');
      });
    }),

    complete: Ember.computed('facets.@each', 'currenctFacet', function () {
      return this.facets.filter(function (pfs) {
        return !pfs.get('nextAnswer');
      });
    }),

    high: Ember.computed('score', function () {
      return this.score > 50;
    }),

    typeKey: Ember.computed('high', 'aspectType.name', function () {
      const keyChar = this.get('aspectType.name') ? this.get('aspectType.name').charAt(0) : "U";
      return this.high ? keyChar : keyChar.toLowerCase();
    }),

    facetTypes: Ember.computed('aspectType.id', function () {
      const aspectTypeId = this.get('aspectType.id');
      return this.store.query('personality-facet-type', { orderBy: 'aspectType', equalTo: aspectTypeId });
    }),

    setupFacets: (0, _emberConcurrency.task)(function* () {
      const setupFacetTask = this.setupFacetTask;
      const aspectTypeId = yield this.get('aspectType.id');
      const facetTypes = yield this.store.query('personality-facet-type', { orderBy: 'aspectType', equalTo: aspectTypeId });
      const facetTypesForSetup = [];

      facetTypes.forEach(function (type) {
        facetTypesForSetup.push(setupFacetTask.perform(type));
      });

      yield (0, _emberConcurrency.all)(facetTypesForSetup);

      yield this.save();
    }),

    setupFacetTask: (0, _emberConcurrency.task)(function* (facetType) {
      const student = this.student;
      const aspect = this;

      const pf = yield this.store.createRecord('personality-facet', {
        student,
        aspect,
        facetType
      });

      yield this.get('facets').pushObject(pf);

      yield pf.save();
    }),

    score: Ember.computed('facets.@each.score', function () {
      let score = 0;
      const total = parseFloat(this.get('facets.length'));
      this.facets.forEach(function (facet) {
        // console.log(score, "+", facet.get('score'));
        score = parseFloat(score) + parseFloat(facet.get('score'));
      });
      const actualScore = score / total;
      return actualScore.toFixed(0);
    }),

    descriptors: Ember.computed('high', 'aspectType.highDescriptors', 'aspectType.lowDescriptors', function () {
      return this.high ? this.get('aspectType.highDescriptors') : this.get('aspectType.lowDescriptors');
    })

  });
});