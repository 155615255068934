define('day-one/_pods/components/assessments/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const inject = Ember.inject;

  exports.default = Ember.Controller.extend({
    sessionManager: Ember.inject.service(),
    person: Ember.computed.alias('sessionManager.person'),
    personality: Ember.computed.alias('person.personality'),
    aspectTypes: Ember.computed.alias('sessionManager.aspectTypes'),
    facetTypes: Ember.computed.alias('sessionManager.facetTypes'),
    questions: Ember.computed.alias('sessionManager.questions'),
    personalityTests: Ember.computed.alias('sessionManager.personalityTests'),

    personalityPolar: Ember.computed("personality.complete", "personality.openness.score", "personality.conscientiousness.score", "personality.extraversion.score", "personality.agreeableness.score", "personality.neuroticism.score", function () {
      const o = parseInt(this.get('personality.openness.score'));
      const c = parseInt(this.get('personality.conscientiousness.score'));
      const e = parseInt(this.get('personality.extraversion.score'));
      const a = parseInt(this.get('personality.agreeableness.score'));
      const n = parseInt(this.get('personality.neuroticism.score'));
      if (this.get('personality.complete') && !isNaN(o) && !isNaN(c) && !isNaN(e) && !isNaN(a) && !isNaN(n)) {
        return [{
          value: o,
          color: "#F7464A",
          highlight: "#FF5A5E",
          label: "openness"
        }, {
          value: c,
          color: "#46BFBD",
          highlight: "#5AD3D1",
          label: "conscientiousness"
        }, {
          value: e,
          color: "#FDB45C",
          highlight: "#FFC870",
          label: "extraversion"
        }, {
          value: a,
          color: "#949FB1",
          highlight: "#A8B3C5",
          label: "agreeableness"
        }, {
          value: n,
          color: "#4D5360",
          highlight: "#616774",
          label: "neuroticism"
        }];
      }
    }),

    personalityRadar: Ember.computed("personality.complete", "personality.openness.score", "personality.conscientiousness.score", "personality.extraversion.score", "personality.agreeableness.score", "personality.neuroticism.score", function () {
      const o = this.get('personality.openness.score');
      const c = this.get('personality.conscientiousness.score');
      const e = this.get('personality.extraversion.score');
      const a = this.get('personality.agreeableness.score');
      const n = this.get('personality.neuroticism.score');
      let data = [parseInt(o), parseInt(c), parseInt(e), parseInt(a), parseInt(n)];
      if (this.get('personality.complete') && !isNaN(o) && !isNaN(c) && !isNaN(e) && !isNaN(a) && !isNaN(n)) {
        return {
          labels: ["openness", "conscientiousness", "extraversion", "agreeableness", "neuroticism"],
          datasets: [{
            label: "My Personality",
            fillColor: "rgba(220,220,220,0.2)",
            strokeColor: "rgba(220,220,220,1)",
            pointColor: "rgba(220,220,220,1)",
            pointStrokeColor: "#fff",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(220,220,220,1)",
            data: data
          }, {
            label: "Company Average",
            fillColor: "rgba(151,187,205,0.2)",
            strokeColor: "rgba(151,187,205,1)",
            pointColor: "rgba(151,187,205,1)",
            pointStrokeColor: "#fff",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(151,187,205,1)",
            data: [28, 48, 40, 19, 96]
          }]
        };
      }
    }),

    actions: {
      scoreActiveQuestion(item) {
        const self = this;
        const personality = this.personality;
        const answer = personality.get('currentAnswer');
        const facet = personality.get('currentFacet');
        const now = new Date();
        answer.set('score', item.rating);
        answer.set('updated', now);
        answer.save().then(function (answer) {
          if (!facet.get('answers')) {
            facet.set('answers', Ember.A());
          }
          facet.get('answers').pushObject(answer);
          facet.save();
        });
      },

      startTest(test) {
        const self = this;
        const person = this.person;
        this.store.createRecord('personality', {
          person: person,
          test: test
        }).save().then(function (personality) {
          person.get('personalities').pushObject(personality);
          personality.setupPersonality();
          person.save();
        });
      },

      scoreTest(test) {
        const self = this;
        const person = this.person;
        this.store.createRecord('personality', {
          person: person,
          test: test
        }).save().then(function (personality) {
          person.get('personalities').pushObject(personality);
          personality.setupPersonality();
          person.save();
        });
      }
    }
  });
});