define('day-one/models/personality-answer', ['exports', 'ember-data/attr', 'ember-data/relationships', 'day-one/models/base-model'], function (exports, _attr, _relationships, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModel.default.extend({
    personality: (0, _relationships.belongsTo)('personality', { async: true, inverse: null }),
    person: (0, _relationships.belongsTo)('person', { async: true, inverse: null }),
    question: (0, _relationships.belongsTo)('personality-question', { async: true, inverse: null }),
    score: (0, _attr.default)('number'),
    updated: (0, _attr.default)('date'),
    percentageScore: Ember.computed('score', function () {
      const percentScore = 25 * (this.score - 1);
      return percentScore.toFixed(0);
    })
  });
});