define('day-one/services/session-manager', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // const union = computed.alias;
  const inject = Ember.inject;

  exports.default = Ember.Service.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    applicationDataLoaded: false,
    userDataLoaded: false,
    activeContact: null,
    // Set in My or Auth Route setupController method
    session: Ember.inject.service(),
    student: null,
    admin: null,
    sessionUid: Ember.computed.alias('session.uid'),
    universityId: Ember.computed.alias('university.id'),
    countryCode: 'US',

    nameSort: ['name'],

    universities: Ember.computed.sort('_universities', 'nameSort'),

    supportedCountries: Ember.computed('countries.[]', function () {
      return this.countries.filter(function (country) {
        return country.get('supported');
      });
    }),

    setupStudentSession: (0, _emberConcurrency.task)(function* () {
      if (!this.student) {
        const student = yield this.getStudentUser();
        this.set('student', student);
      }
      if (!this.appDataLoaded) {
        const appData = yield this.get('loadApplicationData').perform();
        yield this.setProperties(appData);
        this.set('appDataLoaded', true);
      }
      if (!this.studentDataLoaded) {
        const studentId = this.get('student.id');
        const userData = yield this.get('loadStudentData').perform(studentId);
        yield this.setProperties(userData);
        if (this.get('student.university.id')) {
          const universityData = yield this.get('loadStudentUniversityData').perform(this.get('student.university.id'));
          yield this.setProperties(universityData);
          this.set('studentDataLoaded', true);
          this.router.transitionTo('student-profile.index');
        } else {
          this.set('studentDataLoaded', true);
          this.router.transitionTo('student-profile.index');
        }
      }
    }),

    getStudentUser() {
      const uid = this.get('session.uid');
      return this.store.findRecord('student', uid);
    },

    setupAdminSession: (0, _emberConcurrency.task)(function* () {
      if (!this.admin) {
        const admin = yield this.getAdminUser();
        this.set('admin', admin);
      }
      if (!this.appDataLoaded) {
        const appData = yield this.get('loadApplicationData').perform();
        yield this.setProperties(appData);
        this.set('appDataLoaded', true);
      }
      if (!this.adminDataLoaded) {
        const adminData = yield this.get('loadAdminData').perform(this.get('admin.university.id'));
        yield this.setProperties(adminData);
        this.set('adminDataLoaded', true);
      }
    }),

    getAdminUser() {
      const uid = this.get('session.uid');
      return this.store.findRecord('admin', uid);
    },

    loadApplicationData: (0, _emberConcurrency.task)(function* () {
      const store = this.store;
      const countryCode = this.get('countryCode');

      return (0, _emberConcurrency.hash)({
        _universities: store.query('university', { equalTo: countryCode, orderBy: 'country' }),
        countries: store.findAll('country'),
        languages: store.findAll('language'),
        interests: store.findAll('interest'),
        languageProficiencies: store.findAll('language-proficiency'),
        aspectTypes: store.findAll('personality-aspect-type'),
        groupTypes: store.findAll('group-type'),
        musicGenres: store.findAll('music-genre'),
        sports: store.findAll('sport'),
        facetTypes: store.findAll('personality-facet-type'),
        personalityQuestions: store.findAll('personality-question'),
        facetDescriptors: store.findAll('personality-facet-descriptor'),
        aspectDescriptors: store.findAll('personality-aspect-descriptor'),
        personalityTests: store.findAll('personality-test')
      });
    }),

    loadStudentData: (0, _emberConcurrency.task)(function* (studentId) {
      const store = this.store;
      return (0, _emberConcurrency.hash)({
        studentInterests: store.query('student-interest', { orderBy: 'student', equalTo: studentId }),
        studentLanguages: store.query('student-language', { orderBy: 'student', equalTo: studentId }),
        studentCountries: store.query('student-country', { orderBy: 'student', equalTo: studentId }),
        studentCourses: store.query('student-course', { orderBy: 'student', equalTo: studentId }),
        studentColleges: store.query('student-college', { orderBy: 'student', equalTo: studentId }),
        studentGroups: store.query('student-group', { orderBy: 'student', equalTo: studentId }),
        studentSubjects: store.query('student-subject', { orderBy: 'student', equalTo: studentId }),
        engagementScores: store.query('engagement-score', { orderBy: 'student', equalTo: studentId }),
        emotionalScores: store.query('emotional-score', { orderBy: 'student', equalTo: studentId }),
        academicScores: store.query('academic-score', { orderBy: 'student', equalTo: studentId })
      });
    }),

    loadStudentUniversityData: (0, _emberConcurrency.task)(function* (universityId) {
      const store = this.store;
      return (0, _emberConcurrency.hash)({
        university: store.findRecord('university', universityId),
        groups: store.query('group', { orderBy: 'university', equalTo: universityId }),
        courses: store.query('course', { orderBy: 'university', equalTo: universityId }),
        colleges: store.query('college', { orderBy: 'university', equalTo: universityId }),
        subjects: store.query('subject', { orderBy: 'university', equalTo: universityId })
      });
    }),

    loadAdminData: (0, _emberConcurrency.task)(function* (universityId) {
      const store = this.store;
      return yield (0, _emberConcurrency.hash)({
        university: store.findRecord('university', universityId),
        groups: store.query('group', { orderBy: 'university', equalTo: universityId }),
        courses: store.query('course', { orderBy: 'university', equalTo: universityId }),
        colleges: store.query('college', { orderBy: 'university', equalTo: universityId }),
        subjects: store.query('subject', { orderBy: 'university', equalTo: universityId }),
        students: store.query('student', { orderBy: 'university', equalTo: universityId }),
        admins: store.query('admin', { orderBy: 'university', equalTo: universityId })
      });
    }),

    clearAdminData() {
      this.set('courses', null);
      this.set('colleges', null);
      this.set('subjects', null);
      this.set('students', null);
      this.set('admins', null);
    },

    clearStudentData() {
      this.set('studentLanguages', null);
      this.set('studentCountries', null);
      this.set('studentInterests', null);
      this.set('studentCourses', null);
      this.set('studentColleges', null);
      this.set('studentSubjects', null);
      this.set('engagementScores', null);
      this.set('emotionalScores', null);
      this.set('academicScores', null);
      this.set('courses', null);
      this.set('colleges', null);
      this.set('subjects', null);
      this.set('university', null);
    },

    signOut() {
      this.session.close();
      this.clearStudentData();
      this.clearAdminData();
    },

    signIn(provider) {
      const self = this;
      this.session.open("firebase", { provider: provider }).then(function (data) {
        self.get('store').query('student', { uid: data.uid }).then(function (people) {
          if (!people.get('length')) {
            let newstudent = self.store.createRecord('student');
            newstudent.setFromSession(data);
            newstudent.save().then(function (np) {
              self.set('student', np);
              self.loadUserData(np);
            });
          } else {
            self.set('student', people.get('firstObject'));
            self.loadUserData(people.get('firstObject'));
          }
        });
      });
    }
  });
});