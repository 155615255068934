define('day-one/models/subject', ['exports', 'day-one/models/base-type', 'ember-data/relationships', 'ember-data/attr'], function (exports, _baseType, _relationships, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseType.default.extend({
    code: (0, _attr.default)('string'),
    university: (0, _relationships.belongsTo)('university'),
    college: (0, _relationships.belongsTo)('college'),

    courses: Ember.computed('id', function () {
      const id = this.id;
      return this.store.query('course', {
        equalTo: id,
        orderBy: 'subject'
      });
    })
  });
});