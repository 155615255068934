define('day-one/_pods/student/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        firebaseApp: Ember.inject.service(),
        ajax: Ember.inject.service(),
        setupController(controller) {
            // this.get('ajax').post(' https://us-central1-dayonesolutions-pocv1.cloudfunctions.net/getSuggestionData', {
            //     data: {
            //         uid: this.get('session.currentUser.uid')
            //     }
            // }).then(data => {
            //     controller.set("clubs", data.clubs)
            //     controller.set("events", data.events)
            //     controller.set("courses", data.courses)
            //     this.store.findRecord('student', this.get('session.currentUser.uid')).then(student => {
            //         controller.set("student", student)
            //     })
            // })
        }

    });
});