define('day-one/_pods/school-college/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        titleToken(model) {
            return model.get('name');
        },
        model(params) {
            return this.store.findRecord("college", params.college_id);
        }
    });
});