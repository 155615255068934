define('day-one/_pods/components/star-rating/component', ['exports', 'day-one/_pods/components/templates/components/star-rating'], function (exports, _starRating) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['rating-panel'],

    layout: _starRating.default,

    // @arguments
    rating: 0,
    maxRating: 5,
    item: null,
    "on-click": null,

    fullClassNames: 'glyphicon glyphicon-star',
    emptyClassNames: 'glyphicon glyphicon-star-empty',

    stars: Ember.computed('rating', 'maxRating', function () {
      let rating = Math.round(this.get('rating'));
      let starsArray = [];
      for (let i = 1; i <= this.get('maxRating'); i++) {
        starsArray.push({ rating: i, full: rating >= i });
      }
      return starsArray;
    }),

    actions: {
      setRating: function (newRating) {
        this.get('on-click')({
          item: this.get('item'),
          rating: newRating
        });
      }
    }
  });
});