define("day-one/_pods/application/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),

    actions: {
      signIn: function (provider) {
        this.session.open('firebase', { provider: provider }).then(function (data) {
          console.log(data.currentUser);
        });
      },
      signOut: function () {
        this.session.close();
      },
      toggle: function (field) {
        this.set(field, !this.get(field));
      }
    }
  });
});