define('day-one/_pods/school-student/index/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model() {
            return this.modelFor('school-student');
        },

        setupController(controller, model) {
            controller.set('student', model);
        }
    });
});