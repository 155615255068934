define('day-one/models/base-type', ['exports', 'day-one/models/base-model', 'ember-data/attr'], function (exports, _baseModel, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModel.default.extend({
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    _name: (0, _attr.default)('string'),
    _description: (0, _attr.default)('string'),
    color: (0, _attr.default)('string'),
    sequence: (0, _attr.default)('number'),

    initials: Ember.computed('name', function () {
      return this.get('name.length') ? this.name.slice(0, 2).toUpperCase() : this.id.slice(0, 2).toUpperCase();
    })
  });
});