define('day-one/services/password-strength', ['exports', 'ember-cli-password-strength/services/password-strength'], function (exports, _passwordStrength) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _passwordStrength.default;
    }
  });
});