define('day-one/_pods/components/do-pagination/component', ['exports', 'ember-cli-pagination/util', 'ember-cli-pagination/lib/page-items', 'ember-cli-pagination/validate'], function (exports, _util, _pageItems, _validate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentPage: Ember.computed.alias("content.page"),
    totalPages: Ember.computed.alias("content.totalPages"),

    hasPages: Ember.computed.gt('totalPages', 1),

    watchInvalidPage: Ember.observer("content", function () {
      const c = this.content;
      if (c && c.on) {
        c.on('invalidPage', e => {
          this.sendAction('invalidPageAction', e);
        });
      }
    }),

    truncatePages: true,
    numPagesToShow: 10,

    validate: function () {
      if (_util.default.isBlank(this.currentPage)) {
        _validate.default.internalError("no currentPage for page-numbers");
      }
      if (_util.default.isBlank(this.totalPages)) {
        _validate.default.internalError('no totalPages for page-numbers');
      }
    },

    pageItemsObj: Ember.computed(function () {
      let result = _pageItems.default.create({
        parent: this
      });

      Ember.defineProperty(result, 'currentPage', Ember.computed.alias("parent.currentPage"));
      Ember.defineProperty(result, 'totalPages', Ember.computed.alias("parent.totalPages"));
      Ember.defineProperty(result, 'truncatePages', Ember.computed.alias("parent.truncatePages"));
      Ember.defineProperty(result, 'numPagesToShow', Ember.computed.alias("parent.numPagesToShow"));
      Ember.defineProperty(result, 'showFL', Ember.computed.alias("parent.showFL"));

      return result;
    }),

    pageItems: Ember.computed("pageItemsObj.pageItems", "pageItemsObj", function () {
      this.validate();
      return this.get("pageItemsObj.pageItems");
    }),

    canStepForward: Ember.computed("currentPage", "totalPages", function () {
      const page = Number(this.currentPage);
      const totalPages = Number(this.totalPages);
      return page < totalPages;
    }),

    canStepBackward: Ember.computed("currentPage", function () {
      const page = Number(this.currentPage);
      return page > 1;
    }),

    actions: {
      pageClicked: function (number) {
        _util.default.log("PageNumbers#pageClicked number " + number);
        this.set("currentPage", number);
        this.sendAction('action', number);
      },
      incrementPage: function (num) {
        const currentPage = Number(this.currentPage),
              totalPages = Number(this.totalPages);

        if (currentPage === totalPages && num === 1) {
          return false;
        }
        if (currentPage <= 1 && num === -1) {
          return false;
        }
        this.incrementProperty('currentPage', num);

        const newPage = this.currentPage;
        this.sendAction('action', newPage);
      }
    }
  });
});