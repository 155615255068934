define('day-one/models/activity', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        location: _emberData.default.attr('string'),
        city: _emberData.default.attr('string'),
        state: _emberData.default.attr('string'),
        type: _emberData.default.attr('string'),
        is_deleted: _emberData.default.attr('boolean', { defaultValue: false }),
        like_count: _emberData.default.attr('number', { defaultValue: 0 }),
        comment_count: _emberData.default.attr('number', { defaultValue: 0 }),
        category: _emberData.default.attr('string', { defaultValue: '' }),
        start_date: _emberData.default.attr('string'),
        end_date: _emberData.default.attr('string'),
        start_time: _emberData.default.attr('string'),
        end_time: _emberData.default.attr('string'),
        email: _emberData.default.attr('string'),
        url: _emberData.default.attr('string'),
        images: _emberData.default.hasMany('image'),
        score: _emberData.default.attr('number', { defaultValue: 0 })
    });
});