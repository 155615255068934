define('day-one/models/student-sport', ['exports', 'ember-data/attr', 'ember-data/relationships', 'day-one/models/base-model'], function (exports, _attr, _relationships, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModel.default.extend({
    student: (0, _relationships.belongsTo)('student', { async: false, inverse: 'interests' }),
    sport: (0, _relationships.belongsTo)('sport', { async: false })

  });
});