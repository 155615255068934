define('day-one/models/interest', ['exports', 'day-one/models/base-type', 'ember-data/attr', 'ember-data/relationships'], function (exports, _baseType, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseType.default.extend({
    parentInterest: (0, _attr.default)('string')
    // , 	
    // hasParentInterest:  computed.bool('parentInterest.id')

  });
});