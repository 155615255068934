define('day-one/_pods/student-profile/index/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        firebaseApp: Ember.inject.service(),
        saveUser(uId) {
            const newUser = this.store.createRecord('student', {
                id: uId,
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                username: this.username,
                phone: this.phone,
                is_active: this.is_active,
                image_url: this.image_url,
                dob: this.dob
            });
            newUser.save().then(() => {
                this.transitionToRoute('student.data-forms');
            });
        },
        actions: {
            basicSubmitAction: function () {
                let sessionInfo = this.get('session.currentUser');
                if (sessionInfo && sessionInfo.providerData && sessionInfo.providerData[0].providerId === 'twitter.com') {
                    this.saveUser(sessionInfo.uid);
                } else {
                    this.firebaseApp.auth().createUserWithEmailAndPassword(this.email, this.password).then(userData => {
                        this.saveUser(userData.userId);
                    });
                }
            },
            upload(file) {
                this.set("file-upload-error", "");
                if (!this.files) {
                    this.files = [];
                }
                this.files.push(file.blob);
                file.readAsDataURL().then(function (url) {
                    file.set('url', url);
                });
            }
        }
    });
});