define('day-one/_pods/student-profile/old-questions/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        firebaseApp: Ember.inject.service(),
        actions: {
            testSomeThing(data) {
                var ref = this.firebaseApp.database().ref(`userAnswers/${this.get('session.currentUser.uid')}`);
                data.map(answer => {
                    return ref.child(answer.id).set(answer.selected_answer);
                });
                this.transitionToRoute('student.main.home');
            }
        }
    });
});