define('day-one/models/language', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    nativeName: _emberData.default.attr('string'),
    system: _emberData.default.attr('boolean'),
    displayName: Ember.computed('name', function () {
      return this.name.split(/[ ,;]+/)[0];
    })
  });
});