define('day-one/_pods/school-portal/route', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    sessionManager: Ember.inject.service(),

    beforeModel() {
      const isAuthenticated = this.get('session.isAuthenticated');
      if (isAuthenticated) {
        this.get('sessionManager.setupAdminSession').perform();
        const x = this.get('sessionManager.university');
        this.transitionTo('school.index');
      }
    }

  });
});