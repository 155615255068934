define('day-one/models/personality-facet', ['exports', 'ember-data/attr', 'ember-data/relationships', 'day-one/models/base-model'], function (exports, _attr, _relationships, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModel.default.extend({
    aspect: (0, _relationships.belongsTo)('personality-aspect', { async: true, inverse: null }),
    facetType: (0, _relationships.belongsTo)('personality-facet-type', { async: true, inverse: null }),
    student: (0, _relationships.belongsTo)('student', { async: true, inverse: null }),
    answers: (0, _relationships.hasMany)('personality-answer', { async: true, inverse: null }),
    complete: (0, _attr.default)('boolean', { defaultValue: false }),

    high: Ember.computed('score', function () {
      return this.score > 50;
    }),

    score: Ember.computed('answers.@each.percentageScore', function () {
      let score = 0;
      let total = 0;
      this.answers.forEach(function (answer) {
        if (!isNaN(answer.get('percentageScore'))) {
          score = parseFloat(score) + parseFloat(answer.get('percentageScore'));
          total = total + 1;
        }
      });
      const actualScore = score / total;

      return isNaN(actualScore) ? 0 : actualScore.toFixed(0);
    }),

    descriptors: Ember.computed('high', 'facetType.highDescriptors', 'facetType.lowDescriptors', function () {
      return this.high ? this.get('facetType.highDescriptors') : this.get('facetType.lowDescriptors');
    })

  });
});