define('day-one/_pods/personality/index/controller', ['exports', 'ember-cli-pagination/computed/paged-array', 'moment', 'ember-concurrency'], function (exports, _pagedArray, _moment, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        sessionManager: Ember.inject.service(),
        student: Ember.computed.alias('sessionManager.student'),
        personality: Ember.computed.alias('student.personality'),
        aspects: Ember.computed.alias('personality.aspects'),
        testId: 'ipip',

        personalityTest: Ember.computed('testId', function () {
            const testId = this.testId;
            if (testId) {
                return this.store.findRecord("personality-test", testId);
            }
        }),

        questions: Ember.computed.alias('personalityTest.questions'),

        scoreCurrentAnswer: (0, _emberConcurrency.task)(function* (score) {
            let cssClass = 'neutral-answer';

            switch (score) {
                case 1:
                    cssClass = 'strongly-disagree-answer';
                    break;
                case 2:
                    cssClass = 'disagree-answer';
                    break;
                case 4:
                    cssClass = 'agree-answer';
                    break;
                case 5:
                    cssClass = 'strongly-agree-answer';
                    break;
            }
            this.set('answerClass', cssClass);

            // this.set('tinderText', tindertext);


            const personality = this.personality;

            const answer = yield this.get('currentAnswer');

            const facet = yield this.get('currentFacet');

            const now = new Date();

            yield answer.set('score', score);

            yield answer.set('updated', now);

            this.set('answerIsEmpty', true);

            (0, _emberConcurrency.timeout)(1000);

            yield answer.save();

            yield facet.get('answers').pushObject(answer);

            yield facet.save();

            this.set('ques', this.get('ques') + 1);
            this.set('personality.selectedSequence', this.get('ques') + 1);
            this.set('answerIsEmpty', false);

            return Ember.RSVP.Promise.resolve();
        }),

        startTest: (0, _emberConcurrency.task)(function* () {
            const student = this.student;
            const testId = this.testId;
            let test;
            if (testId) {
                test = yield this.store.findRecord("personality-test", testId);
            }
            const personality = yield this.store.createRecord('personality', {
                student,
                test
            });

            yield personality.save();

            yield student.set('personality', personality);

            yield student.save();

            yield personality.get('setupPersonality').perform();
            yield this.set('answerIsEmpty', false);

            yield this.set('ques', 1);

            return Ember.RSVP.Promise.resolve();
        }),

        restartTest: (0, _emberConcurrency.task)(function* () {
            const personality = yield this.get('personality');
            const currentAnswer = yield personality.get('currentAnswer');
            const ques = yield currentAnswer.get('question.sequence');
            yield this.set('answerIsEmpty', false);
            yield this.set('ques', ques);
        }),

        currentAnswer: Ember.computed('pagedAnswers.length', 'ques', 'answerIsEmpty', function () {
            const pas = this.get('pagedAnswers');
            const answer = pas.objectAt(0);
            return this.get('answerIsEmpty') ? null : answer;
        }),

        currentQuestion: Ember.computed('currentAnswer.question.id', function () {
            return this.get('currentAnswer.question');
        }),

        currentFacetType: Ember.computed('currentQuestion.facetType.id', function () {
            return this.get('currentQuestion.facetType');
        }),

        currentAspectType: Ember.computed('currentFacetType.aspectType.id', function () {
            return this.get('currentFacetType.aspectType');
        }),

        currentAspect: Ember.computed('aspects.@each.id', 'currentAspectType.id', function () {
            const aspectTypeId = this.currentAspectType.get('id');
            const aspects = this.aspects.filter(function (aspect) {
                return aspect.get('aspectType', aspectTypeId);
            });
            return aspects && aspects.get('length') ? aspects.get('firstObject') : null;
        }),

        currentFacet: Ember.computed('facets.@each.facetType', 'currentFacetType.id', function () {
            const facetTypeId = this.currentFacetType.get('id');
            const facets = this.facets.filter(function (facet) {
                return facet.get('facetType.id') === facetTypeId;
            });
            return facets && facets.get('length') === 1 ? facets.get('firstObject') : null;
        }),

        facets: Ember.computed('aspects.@each.facets', function () {
            const facets = Ember.A();
            this.aspects.forEach(function (aspect) {
                const aspectFacets = aspect.get('facets');
                facets.pushObjects(aspectFacets.toArray());
            });
            return facets;
        }),

        hasNoNext: Ember.computed('ques', function () {
            return !this.get('ques') || this.get('ques') >= 300;
        }),

        hasNoPrevious: Ember.computed('ques', function () {
            return !this.get('ques') || this.get('ques') <= 1;
        }),

        // setup our query params
        queryParams: ["ques"],

        // set default values, can cause problems if left out
        // if value matches default, it won't display in the URL
        ques: 0,
        perPage: 1,

        // can be called anything, I've called it pagedContent
        // remember to iterate over pagedContent in your template
        pagedAnswers: (0, _pagedArray.default)('personality.sortedAnswers', {
            page: Ember.computed.alias("parent.ques"),
            perPage: Ember.computed.alias("parent.perPage")
        }),

        // binding the property on the paged array
        // to a property on the controller
        totalPages: Ember.computed.oneWay("pagedAnswers.totalPages"),

        actions: {
            nextQuestion() {
                let currentSequence = this.get('ques');
                const newSequence = currentSequence != 300 ? currentSequence + 1 : null;
                this.set('ques', newSequence);
                this.set('personality.selectedSequence', newSequence);
            },
            prevQuestion() {
                let currentSequence = this.get('ques');
                const newSequence = currentSequence != 1 ? currentSequence - 1 : null;
                this.set('ques', newSequence);
                this.set('personality.selectedSequence', newSequence);
            }

        }
    });
});