define('day-one/models/base-model', ['exports', 'ember-data/model', 'ember-data/attr', 'moment', 'day-one/config/environment', 'ember-concurrency'], function (exports, _model, _attr, _moment, _environment, _emberConcurrency) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	function asFromNow(date) {
		const format = !(0, _moment.default)(date).isSame((0, _moment.default)(), 'year') ? 'MMM D, YYYY' : !(0, _moment.default)(date).isSame((0, _moment.default)(), 'day') ? 'MMM D' : '';
		return (0, _moment.default)(date).fromNow(format);
	}

	function asTimestamp(date) {
		return (0, _moment.default)(date).unix();
	}

	exports.default = _model.default.extend({
		rev: (0, _attr.default)('string'),
		createdAt: (0, _attr.default)('date'),
		updatedAt: (0, _attr.default)('date'),
		deletedAt: (0, _attr.default)('date'),
		createdStamp: (0, _attr.default)('number'),
		updatedStamp: (0, _attr.default)('number'),
		deletedStamp: (0, _attr.default)('number'),
		deletingIndex: 0,

		createdFromNow: Ember.computed('createdAt', function () {
			return asFromNow(this.created);
		}),

		// canSave: computed(function() {
		// 	const isntTest = ENV.environment !== 'test';
		// 	const isntMirageEverything = !ENV.USE_MIRAGE_FOR_EVERYTHING;
		// 	const isntSaving = !this.get('isSaving');
		// 	const isntDeleted = !this.get('isDeleted');
		// 	const canSave = isntTest && isntMirageEverything && isntSaving && isntDeleted;
		//
		// 	return canSave;
		// }),

		modelNameKey: Ember.computed('modelName', function () {
			return `_.${this.modelName}`;
		}),

		modelName: Ember.computed.alias('constructor.modelName'),

		save() {
			const isntSaving = !this.isSaving;
			const isntDestroyed = !this.isDestroyed;
			const isntDestroying = !this.isDestroying;
			const canSave = isntSaving && isntDestroyed && isntDestroying;
			const modelName = this.modelName;

			// This will break tests and throw errs in development
			// but will be ignored in production
			(true && !(canSave) && Ember.assert(`Must not attempt to save ${modelName} model concurrently`, canSave));


			if (!canSave) {
				return Ember.RSVP.Promise.resolve();
			}

			return this._super(...arguments);
		},
		//Deleting a accomplishment-value cleanup
		// remove from accomplishment model values hasMany
		// find any claim-accomplishments referencing that accomplishment-value and delete them
		// other stuff

		// Deleting an element cleanup
		// remove from persons hasMany
		// find any claim-accomplishments referencing that element and delete them
		// find any mentions referencing that element set the element to null
		//    update their mention citation things
		// find any markers ...  blas

		// belongsToForCleanup: [{
		// 	name: 'person',
		// 	inverseName: 'elements',
		// 	remove: true,
		// 	delete: true
		// }],
		// hasManyForCleanup: [{
		// 	name: 'claimAccomplishments',
		// 	inverseName: 'element',
		// 	remove: true,
		// 	delete: true
		// }],

		// cleanAndDelete: task(function*(tasks = []) {
		// 	const self = this;
		// 	const belongsTos = yield this.get('belongsToForCleanup');
		// 	const hasManys = yield this.get('belongsToForCleanup');

		// 	belongsTos.forEach((bt) => {
		// 		const name = bt.name;
		// 		const hasManyFieldName = bt.inverseName;
		// 		const hasManyModel = self.get(name);
		// 		tasks.push('removeFromHasMany', hasManyModel, hasManyFieldName, self);
		// 		tasks.push(self.get('removeFromHasMany').perform(hasManyModel, hasManyFieldName, self));
		// 	});

		// 	hasManys.forEach((bt) => {
		// 		const name = bt.name;
		// 		const fieldName = bt.inverseName;
		// 		const hasManyModel = self.get(name);
		// 		modelArray.forEach((model)=> {
		// 			tasks.push(self.get('removeBelongsTo').perform(model, fieldName, self));
		// 			tasks.push(model.get('cleanAndDelete').perform());
		// 		});

		// 	});
		// 	originalState = [];
		// 	tasks.forEach((task, hasManyModel, hasManyFieldName, self)=> {
		// 		tasks.push(self.get('removeBelongsTo').perform(model, fieldName, self));
		// 		tasks.push(model.get('cleanAndDelete').perform());
		// 		else
		// 		model.set('requiresSave', true);
		// 	});

		// 	yield all(tasks);

		// 	return yield self.set('requiresSave', true);
		// }),

		// removeFromHasMany: task(function*(hasManyModel, hasManyFieldName, modelForDelete) {
		// 	hasManyModel.get(hasManyFieldName).removeObject(modelForDelete);

		// 	return yield hasManyModel.save();
		// }),

		// actions: {
		// 	deleteElement() {
		// 		const readyToSave = this.get('cleanAndDelete').perform();
		// 	}
		// }

		// removeBelongsTo: task(function*(belongsToModel, belongsToFieldName, modelForDelete) {
		// 	belongsToModel.set(belongsToFieldName, null);

		// 	modelForDelete

		// 	return yield belongsToModel.save();
		// }),

		// removeBelongsTo: task(function*(belongsToModel, belongsToFieldName, modelForDelete) {
		// 	belongsToModel.set(belongsToFieldName, null);

		// 	modelForDelete

		// 	return yield belongsToModel.save();
		// }),


		removeFromHasMany: (0, _emberConcurrency.task)(function* (relationship, objects = [], saveThis = true) {
			const objArray = Ember.typeOf(objects) === 'array' ? objects : Ember.typeOf(objects) === 'instance' ? [objects] : [];

			if (objArray.get('length')) {
				this.get(relationship).removeObjects(objects);
				if (saveThis) {
					yield this.save();
				}
			} else {
				return Ember.RSVP.Promise.resolve();
			}
		}),

		removeFromBelongsTo: (0, _emberConcurrency.task)(function* (relationship, saveThis = true) {
			this.set(relationship, null);

			if (saveThis) {
				yield this.save();
			}
		}),

		deleteModel: (0, _emberConcurrency.task)(function* (model) {
			const modelName = model.get('modelName') || '';
			model.destroyRecord();
		}),

		deleteModels: (0, _emberConcurrency.task)(function* (models = []) {
			models.forEach(model => this.deleteModel.perform(model));
		})
	});
});