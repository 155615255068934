define('day-one/_pods/groups/controller', ['exports', 'ember-cli-pagination/computed/paged-array', 'moment', 'ember-concurrency'], function (exports, _pagedArray, _moment, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        sessionManager: Ember.inject.service(),
        student: Ember.computed.alias('sessionManager.student'),
        studentInterests: Ember.computed.alias('sessionManager.studentInterests'),
        tinderClass: '',
        dateSort: ['start'],
        universityId: Ember.computed.alias('sessionManager.universityId'),

        groups: Ember.computed('universityId', function () {
            const universityId = this.universityId;
            if (universityId) {
                return this.store.query("group", {
                    orderBy: 'university',
                    equalTo: universityId
                });
            }
        }),

        goBack: (0, _emberConcurrency.task)(function* () {
            const cssClass = 'tinder-back';
            this.set('tinderClass', cssClass);
            this.set('tinderText', 'Go Back');
            const currentPage = this.get('page');
            yield (0, _emberConcurrency.timeout)(100);
            if (currentPage > 1) {
                this.set('page', this.get('page') - 1);
            }

            return Ember.RSVP.Promise.resolve();
        }),

        favorite: (0, _emberConcurrency.task)(function* (group) {
            const cssClass = 'tinder-favorite';
            this.set('tinderText', 'Favorite');
            this.set('tinderClass', cssClass);
            this.set('groupIsEmpty', true);

            const currentPage = this.get('page');
            yield (0, _emberConcurrency.timeout)(100);
            if (currentPage > 1) {
                this.set('page', currentPage + 1);
            }
            this.set('groupIsEmpty', false);

            return Ember.RSVP.Promise.resolve();
        }),

        reviewStudentGroup: (0, _emberConcurrency.task)(function* (group, scoreChange) {
            const cssClass = scoreChange > 0 ? 'tinder-love' : 'tinder-hate';
            const tindertext = scoreChange > 0 ? 'Love it!' : 'Hate it!';
            this.set('tinderClass', cssClass);

            this.set('tinderText', tindertext);
            this.set('groupIsEmpty', true);
            yield (0, _emberConcurrency.timeout)(100);
            //const newScore = studentGroup.get('score') + scoreChange;
            // const studentInterestRatings = [];
            // const updateStudentInterestsTask = this.get('updateStudentInterests');

            // if(studentGroup.get('interests') && studentGroup.get('interests.length')) {
            //     studentGroup.get('interests').forEach(function(interest) {
            //         studentInterestRatings.push(updateStudentInterestsTask.perform(interest, scoreChange))
            //     });
            //     yield all(studentInterestRatings);
            // }
            //studentGroup.set('score', newScore);
            //yield studentGroup.save();

            this.set('page', this.get('page') + 1);
            this.set('groupIsEmpty', false);

            return Ember.RSVP.Promise.resolve();
        }),

        updateStudentInterests: (0, _emberConcurrency.task)(function* (interest, score) {
            const studentInterests = this.get('studentInterests');
            let thisStudentInterest = yield studentInterests.findBy('interest', interest);
            if (!thisStudentInterest || thisStudentInterest.get('id')) {
                const student = yield this.get('student');

                thisStudentInterest = this.store.createRecord('studentInterest', {
                    interest,
                    student,
                    score
                });
            } else {
                const newScore = studentGroup.get('score') + score;
                thisStudentInterest.set('score', newScore);
            }

            yield thisStudentInterest.save();

            return Ember.RSVP.Promise.resolve();
        }),

        // sortedGroups: sort('model', 'dateSort'),

        // filteredGroups: computed('sortedGroups.length', function() {
        //     const groups =  this.get('sortedGroups');
        //     if(groups.get('length')) {
        //         return groups.filter(function(group) {
        //             return !group.get('isSeries') || !group.get('previousInSeries');
        //         });
        //     }
        // }),

        currentGroup: Ember.computed('pagedGroups.length', 'page', 'groupIsEmpty', function () {
            const pes = this.get('pagedGroups');
            const group = pes.objectAt(0);
            return this.get('groupIsEmpty') ? null : group;
        }),

        // setup our query params
        queryParams: ["page", "perPage"],

        // set default values, can cause problems if left out
        // if value matches default, it won't display in the URL
        page: 1,
        perPage: 1,

        // can be called anything, I've called it pagedContent
        // remember to iterate over pagedContent in your template
        pagedGroups: (0, _pagedArray.default)('groups', {
            page: Ember.computed.alias("parent.page"),
            perPage: Ember.computed.alias("parent.perPage")
        }),

        // binding the property on the paged array
        // to a property on the controller
        totalPages: Ember.computed.oneWay("pagedGroups.totalPages")
    });
});