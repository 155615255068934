define('day-one/models/personality-test', ['exports', 'ember-data/attr', 'ember-data/relationships', 'day-one/models/base-model'], function (exports, _attr, _relationships, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModel.default.extend({
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    questions: (0, _relationships.hasMany)('personality-question', { async: true, inverse: null }),

    facetTypes: Ember.computed('questions.length', function () {
      const questions = this.questions;
      return questions.map(function (question) {
        return question.get('facetType.content');
      });
    }),
    allAspectTypes: Ember.computed('facetTypes.length', function () {
      return this.facetTypes.map(function (facetType) {
        return facetType.get('aspectType.content');
      });
    }),
    aspectTypes: Ember.computed.uniq('allAspectTypes')

  });
});