define("day-one/_pods/school-subject/route", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model(params) {
            return this.store.findRecord("subject", params.subject_id);
        }
    });
});