define('day-one/_pods/events/month/controller', ['exports', 'ember-cli-pagination/computed/paged-array', 'moment', 'ember-concurrency'], function (exports, _pagedArray, _moment, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        sessionManager: Ember.inject.service(),
        student: Ember.computed.alias('sessionManager.student'),
        events: Ember.computed.alias('model'),
        queryParams: ["day", "month"],
        actions: {
            incrementMonth(date) {
                const current = this.get('month');
                const next = (0, _moment.default)(current).add(1, 'month');
                this.set('month', next.format('MM-DD-YYYY'));
            },
            setMonth() {
                this.set('month', (0, _moment.default)(date).format('MM-DD-YYYY'));
            },
            decrementMonth() {
                const current = this.get('month');
                const next = (0, _moment.default)(current).add(-1, 'month');
                this.set('month', next.format('MM-DD-YYYY'));
            },
            setDay(date) {
                if ((0, _moment.default)(date).isSame(this.get('day'), 'day')) {
                    this.set('day', null);
                } else {
                    this.set('day', (0, _moment.default)(date).format('MM-DD-YYYY'));
                }
            }
        }
    });
});