define('day-one/models/person', ['exports', 'ember-data/attr', 'ember-data/relationships', 'day-one/models/base-model', 'ember-concurrency'], function (exports, _attr, _relationships, _baseModel, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModel.default.extend({
    systemUser: (0, _attr.default)('boolean'),
    university: (0, _relationships.belongsTo)('university'),
    uid: (0, _attr.default)('string'),
    provider: (0, _attr.default)('string'),
    username: (0, _attr.default)('string'),
    biography: (0, _attr.default)('string'),
    online: (0, _attr.default)('boolean'),
    dob: (0, _attr.default)('date'),
    nameStartsWith: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    personalEmail: (0, _attr.default)('string'),
    schoolEmail: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    usePersonalEmail: (0, _attr.default)('boolean', { defaultValue: true }),
    email: Ember.computed.alias('primaryEmail'),

    location: (0, _attr.default)('string'),

    profileImage: (0, _relationships.belongsTo)('image', { async: true }),

    language: (0, _relationships.belongsTo)('language', { async: true }),
    languages: (0, _relationships.hasMany)('student-language', { async: true }),
    countries: (0, _relationships.hasMany)('student-country', { async: true }),

    // interests:     hasMany('student-interest', {async: true}),

    initial: Ember.computed('firstName', function () {
      return this.firstName ? this.firstName.toUpperCase().substring(0, 1) : '';
    }),

    avatar: (0, _attr.default)('string', { defaultValue: 'avatar1' }),
    avatarUrl: Ember.computed('avatar', function () {
      const avatar = this.avatar ? this.avatar + '.png' : 'avatar1.png';
      return '/assets/icons/' + avatar;
    }),

    fullName: Ember.computed('firstName', 'lastName', function () {
      return this.firstName + ' ' + this.lastName;
    }),

    setNameStartsWith: function () {
      const nsw = this.firstName + '   ';
      this.set('nameStartsWith', nsw.toUpperCase().substring(0, 4));
    },

    setFromSession: (0, _emberConcurrency.task)(function* (data) {
      this.set('uid', Ember.get(data, 'uid'));
      this.set('provider', Ember.get(data, 'provider'));
      this.set('image_url', Ember.get(data.currentUser, 'photoUrl'));
      this.set('email', Ember.get(data.currentUser, 'email'));

      const displayName = Ember.get(data.currentUser, 'displayName') + "";
      let names = displayName.split(' ') || [];

      this.set('lastName', names[1]);
      this.set('firstName', names[0]);
    }).drop()

  });
});