define('day-one/_pods/student-profile/old-questions/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        firebaseApp: Ember.inject.service(),
        activate: function () {
            Ember.$('body').addClass("bg-color-white");
        },
        model() {

            var questionnaire_data = [];

            return this.store.findAll('questionnaire').then(list => {
                list.forEach(element => {
                    element.set('selected_answer');
                    questionnaire_data.pushObject(element);
                });
                return questionnaire_data;
            });
        },
        setupController(controller, model) {
            controller.set("questionnaire", model);
        }
    });
});