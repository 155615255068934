define('day-one/_pods/student-portal/sign-up/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    sessionManager: Ember.inject.service(),

    setupController(controller) {
      controller.setup();
    },

    authenticationRoute: 'home'

  });
});