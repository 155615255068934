define("day-one/_pods/student-profile/address/controller", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            basicSubmitAction: function () {
                this.transitionToRoute("student.data-forms");
            }
        }
    });
});