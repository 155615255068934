define('day-one/_pods/assessments-culture/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const inject = Ember.inject;

  exports.default = Ember.Controller.extend({
    sessionManager: Ember.inject.service(),
    person: Ember.computed.alias('sessionManager.person'),
    personality: Ember.computed.alias('person.personality'),
    aspectTypes: Ember.computed.alias('sessionManager.aspectTypes'),
    facetTypes: Ember.computed.alias('sessionManager.facetTypes'),
    questions: Ember.computed.alias('sessionManager.questions'),
    personalityTests: Ember.computed.alias('sessionManager.personalityTests'),

    actions: {
      scoreActiveQuestion(item) {
        const self = this;
        const personality = this.personality;
        const answer = personality.get('currentAnswer');
        const facet = personality.get('currentFacet');
        const now = new Date();
        answer.set('score', item.rating);
        answer.set('updated', now);
        answer.save().then(function (answer) {
          if (!facet.get('answers')) {
            facet.set('answers', Ember.A());
          }
          facet.get('answers').pushObject(answer);
          facet.save();
        });
      },

      startTest(test) {
        const self = this;
        const person = this.person;
        this.store.createRecord('personality', {
          person: person,
          test: test
        }).save().then(function (personality) {
          person.get('personalities').pushObject(personality);
          personality.setupPersonality();
          person.save();
        });
      },

      scoreTest(test) {
        const self = this;
        const person = this.person;
        this.store.createRecord('personality', {
          person: person,
          test: test
        }).save().then(function (personality) {
          person.get('personalities').pushObject(personality);
          personality.setupPersonality();
          person.save();
        });
      }
    }
  });
});