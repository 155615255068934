define('day-one/_pods/components/student/menu-sidebar/component', ['exports', 'moment', 'ember-concurrency', 'ember-power-calendar-utils'], function (exports, _moment, _emberConcurrency, _emberPowerCalendarUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    sessionManager: Ember.inject.service(),
    store: Ember.inject.service(),
    universityId: Ember.computed.alias('sessionManager.universityId'),
    classNames: ['menu-items-container'],

    calendarIcon: Ember.computed(function () {
      const day = (0, _moment.default)().format('D');
      return `calendar-${day}`;
    }),

    actions: {
      signOut: function () {
        this.session.close();
        this.transitionToRoute(`student-portal.index`);
      }
    }
  });
});