define('day-one/_pods/school-courses/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model() {
            const subjectId = this.modelFor('school-subject').get('id');
            return this.store.query('course', {
                equalTo: subjectId,
                orderBy: 'subject'
            });
        }
    });
});