define('day-one/models/personality-facet-type', ['exports', 'ember-data/attr', 'ember-data/relationships', 'day-one/models/base-model'], function (exports, _attr, _relationships, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModel.default.extend({
    aspectType: (0, _relationships.belongsTo)('personality-aspect-type', { async: true, inverse: null }),
    ipipName: (0, _attr.default)('string'),
    neoName: (0, _attr.default)('string'),
    level: (0, _attr.default)('number'),
    ipipMeanCorrelation: (0, _attr.default)('number'),
    neoMeanCorrelation: (0, _attr.default)('number'),
    ipipAlpha: (0, _attr.default)('number'),
    neoAlpha: (0, _attr.default)('number'),
    highDescriptors: (0, _relationships.hasMany)('personality-facet-descriptor', { async: true, inverse: null }),
    lowDescriptors: (0, _relationships.hasMany)('personality-facet-descriptor', { async: true, inverse: null })
  });
});