define('day-one/_pods/student-profile/address/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        activate: function () {
            Ember.$('body').addClass("bg-color-white");
        }
    });
});