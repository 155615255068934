define('day-one/_pods/application/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    sessionManager: Ember.inject.service(),

    title: function (tokens) {
      tokens = Ember.makeArray(tokens);
      const htmlTitle = tokens.join('&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;');
      const stringTitle = tokens.join(' > ');
      this.set('sessionManager.title', htmlTitle);
      return stringTitle;
    },

    beforeModel: function () {
      return this.session.fetch().catch(function () {});
    },

    afterModel: function () {
      this.transitionTo('student-portal');
    }
  });
});