define("day-one/_pods/student-portal/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    media: Ember.inject.service(),
    actions: {
      studentSignIn: function () {
        this.session.open('firebase', { provider: 'twitter' }).then(function (data) {
          console.log(data.currentUser);
        });
      },
      signOut: function () {
        this.session.close();
      },
      toggle: function (field) {
        this.set(field, !this.get(field));
      }
    }
  });
});