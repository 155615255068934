define('day-one/models/student-language', ['exports', 'ember-data/attr', 'ember-data/relationships', 'day-one/models/base-model'], function (exports, _attr, _relationships, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const presentActfl = function (actfl, replaceWith) {
    return actfl.replace('speak, read, write and/or listen', replaceWith);
  };

  exports.default = _baseModel.default.extend({
    student: (0, _relationships.belongsTo)('student', { async: false, inverse: 'languages' }),
    language: (0, _relationships.belongsTo)('language', { async: false }),
    proficiency: (0, _relationships.belongsTo)('language-proficiency', { async: false }),
    speaking: (0, _relationships.belongsTo)('language-proficiency', { async: false, inverse: null }),
    listening: (0, _relationships.belongsTo)('language-proficiency', { async: false, inverse: null }),
    reading: (0, _relationships.belongsTo)('language-proficiency', { async: false, inverse: null }),
    writing: (0, _relationships.belongsTo)('language-proficiency', { async: false, inverse: null }),
    ratingNumber: Ember.computed('speaking', 'listening', 'reading', 'writing', function () {
      const speaking = this.get('speaking.id') ? this.get('speaking.id') * 2 : 1;
      const listening = this.get('listening.id') ? this.get('listening.id') * 2 : 1;
      const reading = this.get('reading.id') ? this.get('reading.id') * 2 : 1;
      const writing = this.get('writing.id') ? this.get('writing.id') * 2 : 1;
      return speaking + listening + reading + writing + 4;
    }),

    hasBeenRated: Ember.computed('speaking', 'listening', 'reading', 'writing', function () {
      return this.speaking && this.listening && this.reading && this.writing;
    }),
    ratingText: Ember.computed('hasBeenRated', 'ratingNumber', function () {
      if (!this.hasBeenRated) {
        return 'N/A';
      } else {
        if (this.ratingNumber > 20) {
          return this.ratingNumber + '%';
        } else {
          return '<20%';
        }
      }
    }),
    speakingActfl: Ember.computed('speaking', function () {
      return presentActfl(this.get('speaking.description'), 'speak');
    }),
    listeningActfl: Ember.computed('listening', function () {
      return presentActfl(this.get('listening.description'), 'listen');
    }),
    readingActfl: Ember.computed('reading', function () {
      return presentActfl(this.get('reading.description'), 'read');
    }),
    writingActfl: Ember.computed('writing', function () {
      return presentActfl(this.get('writing.description'), 'write');
    })
  });
});