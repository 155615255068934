define('day-one/models/student', ['exports', 'ember-data/attr', 'ember-data/relationships', 'day-one/models/person'], function (exports, _attr, _relationships, _person) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _person.default.extend({
        image_url: (0, _attr.default)('string'),
        score: (0, _attr.default)('number', { defaultValue: 0 }),
        enabled: (0, _attr.default)('boolean', { defaultValue: false }),
        personality: (0, _relationships.belongsTo)('personality', { async: true }),

        engagementScores: (0, _relationships.hasMany)('engagement-score', { async: true }),
        emotionalScores: (0, _relationships.hasMany)('emotional-score', { async: true }),
        academicScores: (0, _relationships.hasMany)('academic-score', { async: true }),

        engagementScore: Ember.computed.alias('engagementScores.lastObject.numericScore'),
        emotionalScore: Ember.computed.alias('emotionalScores.lastObject.numericScore'),
        academicScore: Ember.computed.alias('academicScores.lastObject.numericScore'),

        hasEngagementScore: Ember.computed.bool('engagementScore'),
        hasEmotionalScore: Ember.computed.bool('emotionalScore'),
        hasAcademicScore: Ember.computed.bool('academicScore'),

        divisor: Ember.computed('hasEngagementScore', 'hasEmotionalScore', 'hasAcademicScore', function () {
            const raw = this.hasEngagementScore + this.hasEmotionalScore + this.hasAcademicScore;
            return raw && raw > 0 ? raw : 1;
        }),

        overallScore: Ember.computed('engagementScore', 'emotionalScore', 'academicScore', 'divisor', function () {
            const total = this.engagementScore + this.emotionalScore + this.academicScore;
            return total / this.divisor;
        })

    });
});