define('day-one/_pods/components/school/college-add/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            closeDialog: function () {
                this.closeDialogBox();
            },
            saveClub: function () {
                var newClub = {
                    name: this.name,
                    email: this.email,
                    url: this.url,
                    description: this.description,
                    type: 'club'
                };
                this.saveClubAction(newClub);
            },
            emailValidation: function (email) {
                var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                if (!emailPattern.test(email)) {
                    this.set('errorMessage', "Invalid Email");
                } else {
                    this.set('errorMessage', "");
                }
            }
        }
    });
});