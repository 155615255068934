define('day-one/models/message', ['exports', 'ember-data/attr', 'ember-data/relationships', 'day-one/models/base-model'], function (exports, _attr, _relationships, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModel.default.extend({
    sender: (0, _relationships.belongsTo)('person', { async: true }),
    stream: (0, _relationships.belongsTo)('message-stream', { async: true }),
    messageBody: (0, _attr.default)('string'),
    sent: (0, _attr.default)('date')
  });
});