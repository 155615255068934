define('day-one/components/paper-password', ['exports', 'ember-paper-password/components/paper-password'], function (exports, _paperPassword) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _paperPassword.default;
    }
  });
});