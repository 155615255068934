define('day-one/models/question', ['exports', 'ember-data', 'day-one/models/base-type'], function (exports, _emberData, _baseType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseType.default.extend({
    answers: _emberData.default.hasMany('answer'),
    type: _emberData.default.belongsTo('question-type'),
    format: _emberData.default.belongsTo('answer-format')
  });
});