define("day-one/_pods/components/school/event-card/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        init() {
            this._super(...arguments);
        },
        defaultImage: "/assets/schools/princeton-wallpaper.jpg",

        actions: {
            editEvent: function (data) {
                this.editEventAction(data);
            },
            confirmRemoveEvent: function (data) {
                this.confirmRemoveEventAction(data);
            }
        }
    });
});