define('day-one/transitions/rotate-left', ['exports', 'liquid-fire'], function (exports, _liquidFire) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = rotateBelow;
  function rotateBelow(opts = {}) {
    let direction = 1;
    if (opts.direction === 'cw') {
      direction = -1;
    }
    (0, _liquidFire.stop)(this.oldElement);
    if (this.oldElement) {
      this.oldElement.css('transform-origin', '50% 150%');
    }
    if (this.newElement) {
      this.newElement.css('transform-origin', '50% 150%');
    }
    return _liquidFire.Promise.all([(0, _liquidFire.animate)(this.oldElement, { rotateZ: -90 * direction + 'deg' }, opts), (0, _liquidFire.animate)(this.newElement, { rotateZ: ['0deg', 90 * direction + 'deg'] }, opts)]);
  }
});