define("day-one/_pods/school-portal/index/controller", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['uni'],
        uni: null,

        session: Ember.inject.service(),
        sessionManager: Ember.inject.service(),

        backgroundWallpaper: Ember.computed('sessionManager.university.id', function () {
            return this.get('sessionManager.university.id') ? `/assets/schools/${this.get('sessionManager.university.id')}.jpg` : "/assets/Loginbackground.png";
        }),

        actions: {
            emailValidation: function (e) {
                const email = e.target.value;
                var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                if (!emailPattern.test(email)) {
                    this.set('errorMessage', "Invalid Email");
                } else {
                    this.set('errorMessage', "");
                }
            },
            passwordSignIn: function () {
                this.session.open('firebase', {
                    provider: 'password',
                    email: this.email,
                    password: this.password
                }).then(() => {
                    this.set('errorMessage', '');
                    this.get('sessionManager.setupAdminSession').perform();
                    this.transitionToRoute("school.index");
                }).catch(err => {
                    debugger;
                    this.set('errorMessage', "Invalid username or password");
                    console.log(err);
                });
            }
        }
    });
});