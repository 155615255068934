define('day-one/models/university', ['exports', 'day-one/models/base-type', 'ember-data/attr'], function (exports, _baseType, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseType.default.extend({
    enabled: (0, _attr.default)('boolean'),
    country: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),

    colleges: Ember.computed('id', function () {
      const id = this.id;
      return this.store.query('college', {
        equalTo: id,
        orderBy: 'university'
      });
    }),

    subjects: Ember.computed('id', function () {
      const id = this.id;
      return this.store.query('subject', {
        equalTo: id,
        orderBy: 'university'
      });
    }),

    courses: Ember.computed('id', function () {
      const id = this.id;
      return this.store.query('course', {
        equalTo: id,
        orderBy: 'university'
      });
    }),

    shortName: Ember.computed('name', function () {
      const name = this.name;
      return name.replace(/ .*/, '');
    })

  });
});