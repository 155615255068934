define('day-one/_pods/components/school/survey-card/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init() {
            this._super(...arguments);
        },

        isDisabled: true,

        actions: {
            userChoiceAction: function (e) {
                this.set('question.question_type', parseInt(e));
                if (!this.question.options) {
                    this.set('question.options', [{}]);
                }
            },
            addTagsAction: function () {
                if (!this.question.options) {
                    this.set('question.options', [{}]);
                } else {
                    this.question.options.pushObject({});
                }
            },
            removeTagsAction: function (option) {
                if (this.question.options.length > 1) {
                    this.question.options.removeObject(option);
                }
            },
            enableDDlAction: function () {
                this.set('isDisabled', !this.isDisabled);
            },
            saveQuestionAction: function () {
                this.saveQuestion(this.question);
            },
            deleteQuestionAction: function () {
                this.deleteQuestion(this.question);
            }
        }
    });
});