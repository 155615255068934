define('day-one/models/message-stream', ['exports', 'ember-data/attr', 'ember-data/relationships', 'day-one/models/base-model'], function (exports, _attr, _relationships, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModel.default.extend({
    messages: (0, _relationships.hasMany)('message', { async: true }),
    people: (0, _relationships.hasMany)('person', { async: true }),
    type: (0, _relationships.belongsTo)('message-stream-type', { async: true }),
    title: (0, _attr.default)('string')
  });
});