define('day-one/_pods/school-group/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        options: {
            pie: {
                height: 300,
                width: 300,
                legend: {
                    position: 'bottom'
                },
                chartArea: {
                    top: 10,
                    width: 250,
                    height: 200
                },
                animation: {
                    startup: true,
                    easing: 'inAndOut'
                },
                slices: {
                    0: { color: '4DD5FF' },
                    0: { color: '4DD5FF' },
                    2: { color: 'blue' }
                }
            },
            area: {
                height: 300,
                width: 750,
                curveType: 'function',
                legend: {
                    position: 'bottom'
                },
                intervals: { 'style': 'area' },
                chartArea: {
                    top: 10,
                    width: 650,
                    height: 250
                },
                animation: {
                    startup: true,
                    easing: 'inAndOut'
                }
            }
        },
        actions: {
            onEventClick: function (title) {
                this.transitionToRoute(`admin.main.${title.toLowerCase()}`);
            }
        }
    });
});