define('day-one/models/personality-aspect-type', ['exports', 'ember-data/attr', 'ember-data/relationships', 'day-one/models/base-model'], function (exports, _attr, _relationships, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModel.default.extend({
    name: (0, _attr.default)('string'),
    sequence: (0, _attr.default)('number'),
    highDescriptors: (0, _relationships.hasMany)('personality-aspect-descriptor', { async: true, inverse: null }),
    lowDescriptors: (0, _relationships.hasMany)('personality-aspect-descriptor', { async: true, inverse: null })
  });
});