define('day-one/_pods/groups/route', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });
   exports.default = Ember.Route.extend({
      titleToken() {
         return `Events`;
      }
   });
});