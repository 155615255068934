define('day-one/_pods/student-portal/sign-up/controller', ['exports', 'day-one/_pods/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sessionManager: Ember.inject.service(),
    session: Ember.inject.service(),
    cookies: Ember.inject.service(),
    autoUpdate: Ember.inject.service(),
    help: Ember.inject.service(),
    system: Ember.inject.service(),

    showConflictWarning: Ember.computed.alias('system.showConflictWarning'),
    progress: Ember.computed.alias('sessionManager.progress'),
    progressLabel: Ember.computed.alias('sessionManager.progressLabel'),

    user: Ember.computed.alias('sessionManager.user'),

    setup() {
      this.set('isLoading', true);

      this.cookies.clear('setupErrors');

      this.get('sessionManager.setupSession').perform().then(() => {
        // this.get('tracking').trackEvent('Successfully set up session');
        // app loaded succefully
        this.set('isLoading', false);
        this.set('sessionManager.appLoaded', true);

        // check for updates
        this.autoUpdate.checkForUpdates();

        // load help
        this.get('help.getHelp').perform();
      }).catch(response => {
        // this.get('tracking').trackEvent('Failed setting up session', { data: { response: response } });
        // app loaded with errors
        this.set('isLoading', false);

        // If errors from license, machine, etc. show them and disbale the app
        this.set('setupErrors', this._parseSetupErrors(response));

        this.cookies.write('setupErrors', this.setupErrors);

        this.set('sessionManager.disabled', true);

        this.session.invalidate();
      });
    },
    _parseSetupErrors(err) {
      const payloads = Ember.get(err, 'payload.errors') || [];

      let errors = payloads.map(payload => {
        return payload.detail;
      });

      if (!errors.length) {
        errors.pushObject({
          text: 'server_connection'
        });
      }

      return errors;
    }

  });
});