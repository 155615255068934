define('day-one/models/student-interest', ['exports', 'ember-data/attr', 'ember-data/relationships', 'day-one/models/base-model'], function (exports, _attr, _relationships, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModel.default.extend({
    student: (0, _relationships.belongsTo)('student', { async: true }),
    interest: (0, _relationships.belongsTo)('interest', { async: true })

  });
});