define('day-one/_pods/school-students/index/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            incrementPage: function () {
                this.set('page', 2);
                this.set('limit', 10);
            },
            decrementPage: function () {
                this.set('page', 1);
                this.set('limit', 5);
            },
            goToPerson: function (params) {
                this.transitionToRoute('school-student', params.id);
            }
        }
    });
});