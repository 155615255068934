define('day-one/models/country', ['exports', 'day-one/models/base-type', 'ember-data'], function (exports, _baseType, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseType.default.extend({
    countryCode: _emberData.default.attr('string'),
    countryName: _emberData.default.attr('string'),
    currencyCode: _emberData.default.attr('string'),
    population: _emberData.default.attr('string'),
    fipsCode: _emberData.default.attr('string'),
    isoNumeric: _emberData.default.attr('string'),
    north: _emberData.default.attr('string'),
    south: _emberData.default.attr('string'),
    east: _emberData.default.attr('string'),
    west: _emberData.default.attr('string'),
    latitude: _emberData.default.attr('string'),
    longitude: _emberData.default.attr('string'),
    capital: _emberData.default.attr('string'),
    continentName: _emberData.default.attr('string'),
    continent: _emberData.default.attr('string'),
    areaInSqKm: _emberData.default.attr('string'),
    languages: _emberData.default.attr('string'),
    supported: _emberData.default.attr('boolean'),
    isoAlpha3: _emberData.default.attr('string'),
    geonameID: _emberData.default.attr('string'),
    flagUrl: Ember.computed('countryCode', function () {
      return '/assets/flags/' + this.countryCode + '.png';
    }),
    name: Ember.computed.alias('countryName')
  });
});