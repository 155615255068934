define('day-one/components/paper-expansion-panel/collapsed', ['exports', 'ember-paper-expansion-panel/components/paper-expansion-panel/collapsed'], function (exports, _collapsed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _collapsed.default;
    }
  });
});